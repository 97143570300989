import React from 'react'
import logo from '../images/logo_blue.png';
import { Button } from '../../HospitalComponents/Buttons/Buttons';
import styles from './Footer.module.css'
import { useNavigate } from 'react-router-dom';
import insta from "../images/Instagram-Logo.wine.png"
import linkedin from "../images/vecteezy_linkedin-logo-png-linkedin-icon-transparent-png_18930587.png"
 

function Footer() {
  const Navigate = useNavigate();
    const clickForPatients = () => {
       Navigate('/forPatient')
      };
       
      const onClickPartner = () => {
        window.open('/home', '_blank');
      }


  return (
    <div className={`${styles.content} ${styles.footerContainer}`}>
    <div className={`${styles.header} ${styles.header2}`}>
      <img src={logo} alt='logo' />
     
    </div>

    <div className={styles.footer}>
      <div className={`${styles.footerChild} ${styles.footerChildLeft}`}>
        <p className={`text_Acron ${styles.footerLeftText}`}>One stop solution to your healthcare needs</p>

        <div className={styles.footerBtnContainer}>
          <div className={`${styles.footerBtn} ${styles.partnerFooterBtn}`}>
            <Button onClick={clickForPatients}>
              <p style={{ fontWeight: 'bold' }}> Apply for 0% finance</p>
            </Button></div>
          <div className={styles.footerBtn}><Button onClick={onClickPartner}>Partner with us</Button></div>

        </div>
      </div>
      <div className={styles.footerChild}>
        <ul>
        <li><a href="/" class="text_Cabinet" rel="noopener noreferrer"><img src={insta} alt="insta"></img></a></li>
        <li><a href="https://www.linkedin.com/company/medi-dhan/" class="text_Cabinet" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt='linkedin'></img></a></li>
        {/* <li><a href="/home" class="text_Cabinet" target="_blank" rel="noopener noreferrer">Facebook</a></li> */}
        {/* <li><a href="/home" class="text_Cabinet" target="_blank" rel="noopener noreferrer">Twitter</a></li> */}

          </ul>
        <p className={`text_Cabinet ${styles.footerRightText}`}>©MediDhan 2024. All Rights Reserved.
          Made with ❤️ for Bhartiyans</p>
      </div>

    </div>


  </div>
  )
}

export default Footer;
