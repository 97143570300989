import React from 'react';
import styles from './FinalVerification.module.css'; // Import the CSS file
import varifiedAnimation from '../images/varifiedAnimation.json';
import Lottie from "lottie-react"
import logo from "../images/logo.png";
import logoHospital from "../images/logoHospital.png"
import logoService from '../images/logoService.png';
import { Button } from '../Buttons/Buttons';
import {useNavigate} from 'react-router-dom';

const IdentityVarified = () => {
  const Navigate = useNavigate();
   const onClickNext = () => {
    Navigate('/user/dashboard');
   }
  return (
    <div className={styles.identity_container}>
      <div className={styles.logoContainer}>
        <img src={logo} alt='logo' ></img>
        <span></span>

      </div>

      <div className={styles.identity_logo}>
        <Lottie className={styles.lottie} animationData={varifiedAnimation} />
      </div>

      <div className={styles.identity_text}>
        <img src={logoHospital} alt='Hospital Logo' className={styles.logo} />
        <p className={`text_Acron ${styles.p3}`}>Narayana Hospital</p>
      </div>

      <p className={`text_Acron ${styles.amount}`}>₹5,00,000</p>
      <p className={`text_Cabinet ${styles.time}`}>Apr 01, 10:39 PM</p>

      <div className={styles.service}>
        <img src={logoService } alt='logoServic'></img>
        <span >IVF</span>
      </div>

      <div className={styles.btn}>
        <Button onClick={onClickNext}>
        Go to home
        </Button>
      </div>
    </div>



  );
}

export default IdentityVarified;