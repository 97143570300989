import React, { useState, useRef,useEffect } from 'react';
import { useNavigate, useLocation,Link } from 'react-router-dom';
import styles from "./MobileVarification.module.css";
import arrow_back from "../../common/images/arrow_back.png";
import logoEdit from "../../common/images/logoEdit.png";
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import CountdownTimer from './Counter';
// import firebase from 'firebase';
import { auth } from '../../../firebase';
import PhoneInput from "react-phone-input-2";
// import { auth1 } from '../../firebase1';



<script src="https://www.google.com/recaptcha/api.js" async defer></script>


const MobileVerification = (props) => {
  // const location = useLocation(); // Use useLocation hook to get location
  let { user, phone,hospitalName,location } = props; // Get user state from location
  // const user = JSON.parse(userState)
  // console.log(user.phoneNumber);
  let flag = false;
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const otpInputs = useRef([]);
  const [newUser, setNewUser] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const navigate = useNavigate();
  const [optVerify, setOtpVerify] = useState(false);
  const [edit, setEdit] = useState(false);
  const [newPhoneNumber, setPh] = useState('');
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const recaptchaVerifierRef = useRef(null);
  const [resendTimer, setResendTimer] = useState(100); // Initial countdown value
const [isResendDisabled, setIsResendDisabled] = useState(false);

// useEffect(() => {
//   let interval;
//   if (resendTimer > 0) {
//     interval = setInterval(() => {
//       setResendTimer((prevTimer) => prevTimer - 1);
//     }, 1000);
//   } else {
//     setIsResendDisabled(false);
//     clearInterval(interval);
//   }
//   return () => clearInterval(interval);
// }, [resendTimer]);



  const editNuber = () => {
    if(newPhoneNumber.length!==0){
      setPh('');
    }
    setEdit(!edit);

  }
 


  
  const createRecaptchaVerifier = () => {
    
    try {

      console.log("in captcha");
      console.log(auth)
      // recaptchaVerifierRef.resendTimer();
      if (!recaptchaVerifierRef.current) {
        recaptchaVerifierRef.current = new RecaptchaVerifier('recaptcha', {
          size: 'invisible',
        }, auth); // Ensure that auth is correctly initialized
      }
      
      return recaptchaVerifierRef.current;
    } catch (err) {
      console.error("Error creating RecaptchaVerifier:", err);
      throw err; // Rethrow the error to handle it in the calling function
    }
  };
  
    
 

  
  const handleResendOTP = async() => {
    // Logic to resend OTP
  try{
    const recaptchaVerifier = createRecaptchaVerifier();
    
    // Resend OTP by calling SendOtp function
    const confirmationResult = await signInWithPhoneNumber(auth, phone, recaptchaVerifier);
    
    console.log('Resent Confirmation result:', confirmationResult);
    
    setNewUser(confirmationResult); // Update newUser with the new confirmation result
    setResendTimer(10); // Reset countdown timer
    setIsResendDisabled(true);
    setPh(phone)
     // Disable "Resend OTP" button
    
  }catch (err) {
    console.error("Error resending OTP:", err);
    // Handle error gracefully
  }
    // Disable "Resend OTP" button
  };
  const updatePhoneNumberAndResendOTP = async () => {
    try {
        // console.log(auth);
        console.log(newPhoneNumber);
        const recaptchaVerifier = createRecaptchaVerifier();
        const confirmationResult = await signInWithPhoneNumber(auth, newPhoneNumber, recaptchaVerifier);
        console.log('Confirmation result:', confirmationResult);
        setNewUser(confirmationResult);
        setOtpSent(true); // Set otpSent to true when OTP is sent

        await updateNewPhoneDatabase();

        setEdit(!edit);
    } catch (err) {
        console.error(err);
    }
};
const updateNewPhoneDatabase = async()=>{
  const response = await fetch ("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/user/api/updateEditedPhoneNumber",{
    method:'post',
    headers:{'Content-Type':'application/json'},
    body:JSON.stringify({oldPhoneNo:phone,editedPhoneNo:newPhoneNumber})
  });
  
}
  
  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      otpInputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace') {
      e.preventDefault(); // Prevent default Backspace behavior
      if (otp[index] !== '') {
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
      } else if (index > 0) {
        otpInputs.current[index - 1].focus(); // Move cursor to the leftmost empty input box
      }
    }
  };

  const verifyOtp = async () => {
    // setOtpVerify(true)
    try {
      const OTP = otp.join('');
      console.log(OTP);
      console.log(phone)
      if (newUser) {
        await newUser.confirm(otp.join('')).then(()=>{
           flag = true;
        }).catch((err)=>{
          flag = false;
          alert("Invalid code"+err);
        });
        phone = newPhoneNumber;
      } else {
        await user.confirm(otp.join('')).then(()=>{
          
        flag = true;
        }).catch((err)=>{
          flag = false;
          alert("Invalid code"+err);

        });
      } // Join the OTP array into a string
//       // Handle successful verification
//       // alert("OTP verified");
     
    if(flag){
      const response = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/user/api/savePhoneNumber",{
        method:'post',
        headers: {
          'Content-Type': 'application/json'
        },

        body: JSON.stringify({phoneNumber: phone,hospitalName:hospitalName,hospitalLocation:location})
      })
      const data = await  response.json();
      console.log(response);
      if(response.ok){
        const userId = data.userId;
        localStorage.clear();
        localStorage.setItem("phone", phone);
localStorage.setItem("userId", userId);
console.log(localStorage)
      if(response.status === 202){
        navigate("/user/dashboard")
      }
      else if(response.status===201 || response.status === 200){
          alert("Phone registered successfull")
        
          console.log(userId);
          setOtpVerify(true);
         

        
          console.log(localStorage);
        }
      }
      else{
        alert("otp not verified");
      }

      // Navigate back to login page after successful verification
    }
  }
    catch (err) {
      console.error(err);
      // Handle error gracefully
    }
  
  };
  if (optVerify) {
    navigate("/user/panVarification");
  }
 
 


  const handleChange = (value) => {

    setPh('+' + value);

    // Hide the placeholder if there is any input value
    setShowPlaceholder(value === '' || value === '+' ? true : false);
  }


  return (
    <div className={styles.container}>
      {!edit ?
        (
          <>
            <div className={styles.back_button}>
              <span
                onClick={() => navigate("/user/home")}
                style={{ cursor: 'pointer', display: 'inline-block', background: 'transparent' }}
              >
                <img src={arrow_back} alt='arrow_back' style={{ width: '32px', height: '32px' }} />
              </span>
            </div>

            <div className={styles.paragraph_container}>
              <p className={styles.paragraph}>Verify your mobile number</p>
            </div>

            <div className={styles.otp_info}>
              <p>We have shared the OTP to your mobile number
                <span className={styles.whitespace}> </span>
                {/* <span className={styles.plus}>+</span> */}
                <span className={styles.mobile_number}>{!newPhoneNumber.length?phone:newPhoneNumber}</span>
                {/* {phone} */}

                <span
                  className={styles.edit_number}
                  onClick={editNuber}
                  style={{ cursor: 'pointer', display: 'inline-block', background: 'transparent' }}
                >
                  <img src={logoEdit} alt={logoEdit} />
                </span>
              </p>
            </div>

            <div className={styles.otp_container}>
              {otp.map((value, index) => (
                <input
                  key={index}
                  ref={(el) => otpInputs.current[index] = el}
                  type="text"
                  className={styles.otp_input}
                  placeholder="X"
                  maxLength="1"
                  value={value}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                />
              ))}
            </div>
            <div id="recaptcha" className={styles.recaptcha_container}></div>
                  
            <div className={styles.resend_otp}>
            {!isResendDisabled ? (
              <CountdownTimer initialTime={10} onComplete={() => setIsResendDisabled(true)} />
            ) : (
              <Link href='#' className={styles.resend} onClick={handleResendOTP}>Resend</Link>
            )}
</div>
      <div className={styles.buttonbtnContiner}>
            <button className={styles.continue_button_mobile} onClick={verifyOtp}>
              <p>Continue</p>
            </button>
            </div>
          </>

        ) : (

          <>
            <div className={styles.back_button}>
              <span
                onClick={editNuber}
                style={{ cursor: 'pointer', display: 'inline-block', background: 'transparent' }}
              >
                <img src={arrow_back} alt='arrow_back' style={{ width: '32px', height: '32px' }} />
              </span>
            </div>
            {/* <div className={styles.paragraph_container}>
              <p className={styles.paragraph}>Verify your mobile number</p>
            </div> */}

            <div className={styles.form_container}>
              <label className={styles.form_label}>Re-enter your mobile number</label>
              <div className={styles.phone_input_container}>
                <PhoneInput
                  country={"in"}
                  value={newPhoneNumber}
                  onChange={handleChange}
                  placeholder={showPlaceholder ? "Enter mobile number" : ""}
                  inputStyle={{ width: '100%', height: '70px' }}
                  containerStyle={{ marginTop: '10px' }}
                />
                {showPlaceholder && <span className={styles.phone_input_placeholder}><p>Enter mobile number</p></span>}

                <div className={styles.overlay}>
                  <div className={styles.captcha_container}>
                   
                    {/* Additional elements or instructions */}
                  </div>
                </div>

              </div>



            </div>

            <div id="recaptcha" className={styles.recaptcha_container}></div>
            

            <button className={styles.continue_button} onClick={updatePhoneNumberAndResendOTP}>
              <p>Send OTP</p>
            </button>
          </>

        )


      }


    </div>
  );
}

export default MobileVerification;
