import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import styles from "./AadhaarAndPan.module.css";
import document_management_photo from "../images/document_management_photo.png"
import logoDigilocker from "../images/logoDigilocker.png";

const AadhaarAndPan = () => {
  const [otp, setOtp] = useState(['', '', '', '']);
  const otpInputs = useRef([]);
  const navigate = useNavigate();

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      otpInputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace') {
      e.preventDefault(); // Prevent default Backspace behavior
      if (otp[index] !== '') {
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
      } else if (index > 0) {
        otpInputs.current[index - 1].focus(); // Move cursor to the leftmost empty input box
      }
    }
  };

  const onClick = () => {
    const enteredOtp = otp.join('');
    alert("Hello this is your otp - " + enteredOtp);
    // navigate("/login");
  };
  const clickmanual =()=>{
    console.log(localStorage)
    navigate("/user/aadhaarVarification");
  }

  return (
    <div className={styles.container}>
      <div className={styles.image_container}>
        
          <img src={document_management_photo} alt='document_management_photo'   />
        
      </div>

      <div className={styles.paragraph_container}>
        <p className={styles.paragraph}>Verify your Aadhaar and PAN card</p>
      </div>

       

       

      
<div className={styles.buttonCont}>
      <button className={styles.continue_with_button} onClick={onClick}>
        <p>Continue with</p> 
        <span>
        <img src={logoDigilocker} alt='logoDigilocker'   />
        </span>
      </button>
      </div>
      <div style={{cursor:'pointer'}} className={styles.resend_otp}>
      <p onClick={clickmanual}>Continue manually</p>
        
      </div>
    </div>
  );
}

export default AadhaarAndPan;
