import React, { useState, useEffect } from 'react';
import styles from "./PersonalDetail.module.css"
import { Navigate, useNavigate } from 'react-router-dom';

import arthopaedicSVG from '../../common/images/arthopaedic.svg';
import { Button, BackButton } from '../Buttons/Buttons';
import rupeesImage from '../../common/images/rupeesImage.png';

import FileUpload from '../FileUpload/FileUpload';
// import ToggleSwitch from '../ToggleSwitch/ToggleSwitch'
import logoCall from '../../common/images/logoCall.png';
const PersonalDetail = () => {
  const Navigate = useNavigate();
  const [salarySlip, setSalarySlip] = useState(null);
  const [bankStatement, setBankStatement] = useState(null);
  let [newsletter, setNewsletter] = useState(false);
  const [services, setServices] = useState([
    { id: 1, name: "Self employed", logo: "../images/arthopaedic.png" },
    { id: 2, name: "Salaried employee", logo: "path/to/logo2.png" },
  ]);
const userId = localStorage.getItem("userId")
  const [boxClicked, setBoxClicked] = useState(false);
  const [clickedService, setClickedService] = useState(null);

  const handleServiceClick = (id) => {
    setClickedService(id);
    setBoxClicked(true);
  };
  const onClick = async () => {
    const formData = new FormData();
    formData.append('userID', userId);
    if (salarySlip) {
      formData.append('salarySlip', salarySlip); // Append file data
    }
    if (bankStatement) {
      formData.append('bankStatement', bankStatement); // Append file data
    }
  
    try {
      const response = await fetch('https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/user/api/upload', {
        method: 'POST',
       
        body: formData
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log(result);
        Navigate('/documentsVerification');
      } else {
        console.error('Failed to upload files');
      }
    } catch (error) {
      console.error('Error in uploading files: ', error);
    }
  };
  

  
  const handleSalarySlipUpload = (event) => {
    const uploadedSalarySlip = event.target.files[0];
    setSalarySlip(uploadedSalarySlip);
  };
  const handleBankStatementUpload = (event) => {
    const uploadedBankStatement = event.target.files[0];
    setBankStatement(uploadedBankStatement);
  };


  const removeSalarySlip = () => {
    setSalarySlip(null);
  };
  const removeBankStatement = () => {
    setBankStatement(null);
  };
  const onNewsletterChange = (checked) => {
    setNewsletter(checked);
  };
  return (
    <div className={styles.container}>

      <div className={styles.back_btn}>
        <BackButton></BackButton>
        <div className={styles.RequestAmount}>
          <p className='text_Cabinet'>Request for ₹5,00,000</p>

          <img src={rupeesImage} alt='rupeesImage'></img>

        </div>
      </div>
      <div className={styles.text}>
        <p className={` text_Acron ${styles.text1}`}>Personal detailse</p>

        <p className={` text_Cabinet ${styles.text2} `}>Select your occupation type </p>

      </div>
      <div className={styles.services}>
        {services.map(service => (
          <div
            key={service.id}
            className={`${styles.serviceBox} ${clickedService === service.id ? styles.clicked : ''}`}
            onClick={() => handleServiceClick(service.id)}
          >
            <div className={styles.serviceImage}>
              <img src={arthopaedicSVG} alt="Arthopaedic" className={styles.vectorImage} />
            </div>

            <div className={styles.serviceName}>
              <p className={`${styles.p3} `}>{service.name}</p>
            </div>
          </div>
        ))}

      </div>

      {
        boxClicked
        &&
        <div className={styles.details}>
          <p className={` text_Cabinet ${styles.text3} `}>Upload the documents</p>
          <div className={styles.inputBox}>
            <div className={styles.txt}>
              <p className={`text_Acron ${styles.txt1}`}>3 months salary slip <span className={styles.spn}>*</span></p>
              <p className={`text_Cabinet ${styles.txt2}`}>pdf, xlsx, doc</p>
            </div>
            <div className={styles.input}>
              {
                <FileUpload
                  labelFor="salarySlipInput"
                  onChange={handleSalarySlipUpload}
                  file={salarySlip}
                  onRemove={removeSalarySlip}
                />
              }
            </div>
          </div>
          <div className={`${styles.inputBox} ${styles.inputBox2}`}>
            <div className={styles.txt}>
              <p className={`text_Acron ${styles.txt1} ${styles.txt11}`}>3 months bank statement <span className={styles.spn}>*</span></p>
              <p className={`text_Cabinet ${styles.txt2}`}>pdf, xlsx, doc</p>
            </div>
            <div className={styles.input}>
              {
                <FileUpload
                  labelFor="bankStatementInput"
                  onChange={handleBankStatementUpload}
                  file={bankStatement}
                  onRemove={removeBankStatement}
                />
              }
            </div>
          </div>

          
          <div className={styles.btn}>
            <Button onClick={onClick} >
            <p>Sign eNACH & claim loan</p>
            </Button>

          </div>
          <div className={styles.contactsUs}>
            <img src={logoCall} alt='logoCall' />
             
            <p className='text_Cabinet'>Contact us for help</p>
            
          </div>
        </div>
      }
    </div>

  );
};

export default PersonalDetail;