import { React, useState } from 'react';
import videoSource from '../../HospitalComponents/Video/MedidhanInto.mp4'
import ServiceBox from '../../HospitalComponents/ServiceBox/ServiceBox';
import styles from './LandingPage.module.css'; // Import the CSS file
import logo from '../images/logo_blue.png';
import bg from '../images/bg.png';
import logofitness from '../images/logofitness.png';
import rupeesImage2 from "../images/rupeesImage2.png"
import rupeeFlag1 from "../images/rupeeFlag1.png";
import rupeeFlag2 from "../images/rupeeFlag2.png";
import arrowDownGreen from "../images/arrowDownGreen.png";
import arthopaedicSVG from '../images/arthopaedic.svg';
import crossBackWhite from '../images/crossBackWhite.png'
import bgLanding1 from '../images/bgLanding1.png';
import updownImage from '../images/updownImage.png'
import phoneLayoutLanding from '../images/phoneLayoutLanding.png'
import iPhone25 from '../images/iPhone25.png'
import animalsBird from '../images/animalsBird.png';
import verticalLine from '../images/verticalLine.png'
import addressImage from '../images/addressImage.png'
import healthCareProvider from '../images/healthCareProvider.png'
import AnimatedText from '../../HospitalComponents/AnimatedText/AnimatedText';

import orthopaedicReplacement from '../images/orthopaedicReplacement.png'
import generalSurgery from '../images/generalSurgery .png'
import ivf from '../images/ivf.png'
import rootCanal from '../images/rootCanal.png'
import fertility from '../images/fertility.png'
import hairTransplant from '../images/hairTransplant.png'
import scalpReduction from '../images/scalpReduction.png'
import dentalRestoration from '../images/dentalRestoration.png'
import cryotherapy from '../images/cryotherapy.png'
import leftYellowGradient from '../images/leftYellowGradient.png';
import logoYoutube from "../images/logoYoutube.png"
import { useNavigate, useLocation } from 'react-router-dom';
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import { Button } from '../../HospitalComponents/Buttons/Buttons';
import plus from "../images/plus.png"
import MapComponent from '../../HospitalComponents/Mapcomponent/MapComponent';
import Instagram from '../images/Instagram-Logo.wine.png';
import loc from "../images/icons8-google-maps-48.png"
import mail from "../images/icons8-mail-48.png";
import phone from "../images/icons8-phone-50.png";
import img1 from "../images/image 18763.png";
import img2 from "../images/image 18764.png";
import img3 from "../images/image 18765.png";
import img4 from "../images/image 18767.png";
import img5 from "../images/image 18768.png";
import img6 from "../images/image 18770.png";
import img7 from "../images/image 18771.png";
import homeImg from "../images/iPhone 15 Pro Portrait Mockup.png"

const LandingPage = () => {
  const Navigate = useNavigate();

  const [isRotated, setIsRotated] = useState(false);
  const [hoverEnabled, setHoverEnabled] = useState(true);
  const [forPatients, setForPatients] = useState(false);
  const [showAllServices, setShowAllServices] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 681);
  const [isMobileView2, setIsMobileView2] = useState(window.innerWidth <= 1300);

  const clickForPatients = () => {
    setForPatients(true);
  }
  const clickForAbout = () => {
    Navigate('/aboutUs')
  }
  const onClickFinance =() =>{
    Navigate('/forPatient');
   }
   const onClickPartner =() => {
    window.open('/hospital/', '_blank');
   }
  

  const handleRotateOnHover = () => {

    setIsRotated(true);

  };
  const toggleShowAllServices = () => {
    setShowAllServices(!showAllServices);
  };


  // const handleMouseLeave = () => {
  //   if (hoverEnabled) {
  //     setIsRotated(false);
  //   }
  // };

  const handleRotate = () => {
    // Disable hover effect
    setIsRotated(false);


  };

  const servicesData = [
    { name: "Orthopaedic surgery", image: arthopaedicSVG },
    { name: "Orthopaedic Replacement", image: orthopaedicReplacement },
    { name: "General surgery", image: generalSurgery },
    { name: "IVF", image: ivf },
    { name: "Root canal", image: rootCanal },
    { name: "Fertility", image: fertility },
    { name: "Hair transplant", image: hairTransplant },
    { name: "Scalp reduction", image: scalpReduction },
    { name: "Cryotherapy", image: cryotherapy },
    { name: "Dental restoration", image: dentalRestoration }
  ];

  const partnerImages = [img1, img2, img3, img4, img5, img6, img7];

  return (
    <div className={styles.main_container}>

      <div className={styles.headerContainer}>
        <Header   />
      </div>
      <div className={styles.content_container}>

        <div className={styles.content}>

          <div className={styles.text1Container}>

            <div className={styles.text1} >
              <img src={rupeesImage2} alt='rupeesImage2' className={styles.rupeesImage2}></img>
              <div className={styles.text1_text}>
               <div className={styles.leftYellowGradientsContainer}>
                
              {/* <div className={styles.leftYellowGradientsImg}></div> */}

               </div>



               
                <p className={`text_Acron ${styles.p1}`}>
                  Finance your healthcare with  <span style={{ fontWeight: 'bold' }}>one click</span>
                </p>
                <AnimatedText />
                <img src={arrowDownGreen} alt='arrowDownGreen' className={styles.arrowDownGreen}></img>
              </div>
              <div className={styles.rightSkyGradientsContainer}>
                
                {/* <img src={bg} alt='leftYellowGradient' className={styles.leftYellowGradients}></img> */}
  
                 </div>
              <img src={rupeesImage2} alt='rupeesImage2' className={styles.rupeesImage23}></img>
              
            </div>

          </div>

          <div className={styles.imageContainer}>
            <img src={rupeeFlag1} alt='rupeeFlag1' className={styles.rupeeFlag1}></img>
            <img src={logofitness} alt='logofitness' className={styles.logofitness}></img>

            <img src={rupeeFlag2} alt='rupeeFlag2' className={styles.rupeeFlag2}></img>
          </div>

        </div>


        <div className={styles.content}>

          <div className={styles.serviceProvided}>
            <img src={updownImage} alt='updownImage'></img>
              <p className='text_Cabinet' style={{fontWeight:'bold'}}>Services Provided</p>
            <img src={updownImage} alt='updownImage'></img>

          </div>

          <div className={styles.service_container}>
            <div className={styles.services}>
              {servicesData.slice(0, isMobileView && !showAllServices ? 4 : servicesData.length).map((service, index) => (
                <ServiceBox key={index} serviceName={service.name} serviceImage={service.image} />
              ))}
              {isMobileView && !showAllServices && (
                <div className={styles.serviceBox} onClick={toggleShowAllServices}>
                  {/* <div className={styles.serviceImage}>
                    <span className={styles.plusIcon}>+</span>
                  </div>
                  <div className={styles.serviceName}>
                    <p className={styles.p3}>More</p>
                  </div> */}
                   <ServiceBox  serviceName='More Service ' serviceImage={plus}  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={`${styles.content} ${styles.phoneContent}   ${isRotated ? styles.horizontal : ''} `}>
          <div className={`${styles.loanInstructions}  ${isRotated ? styles.horizontalloanInstruction : ''} `}>

            <p className={`text_Acron ${styles.time}`}>
              Get loan in <span>5 minutes</span>
            </p>
            <div className={styles.div1}>
              <p className={`text_Acron ${styles.orderNumber}`}>1</p>
              <div className={`${styles.rightBox} ${styles.rightBox1}`}>
                <p className={`text_Acron ${styles.upperText}`}>Scan QR code</p>
                <p className={`text_Cabinet ${styles.lowerText}`}>scan qr code at your healthcare facility</p>

              </div>
            </div>
            <div className={styles.div1}>
              <p className={`text_Acron ${styles.orderNumber}`}>2</p>
              <div className={styles.rightBox}>
                <p className={`text_Acron ${styles.upperText}`}>Register Mobile Number</p>

                <p className={`text_Cabinet ${styles.lowerText}`}>help us to verify your identification</p>

              </div>
            </div>
            <div className={styles.div1}>
              <p className={`text_Acron ${styles.orderNumber}`}>3</p>
              <div className={styles.rightBox}>
                <p className={`text_Acron ${styles.upperText}`}>Sit back and relax</p>
                <p className={`text_Cabinet ${styles.lowerText}`}>easy approval and amount desbursel process</p>

              </div>
            </div>
            <div className={styles.btn}>
              <Button onClick={onClickFinance}>
                Apply for 0% finance
              </Button>
            </div>
          </div>
          <div className={`${styles.phone} ${isRotated ? styles.horizontalPhone : ''} ${isMobileView2 ?styles.hidebackground:""}` }
            onMouseEnter={handleRotateOnHover}
          >
            {isMobileView2 ? (
          <img 
            alt='home' 
            src={homeImg} 
            className={`${styles.iPhone25} ${isRotated ? styles.hideimage : ''} `} 
          />
        ) : (
          <img 
            src={iPhone25} 
            alt='iPhone25' 
            className={`${styles.iPhone25} ${isRotated ? styles.hideimage : ''}`} 
          />
        )}

<img 
  src={logoYoutube} 
  alt='logoYoutube' 
  className={`${styles.logoYoutube} ${isRotated ? styles.hideimage : ''}`}
/>

            {isRotated &&
              <>
                <div className={styles.horizontalideoContainer}  >
                  <video width="100%" height="100%" controls autoPlay
                    muted
                    className={styles.horizontalVideoElement}>
                    <source src={videoSource} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className={styles.crossBotton} onClick={handleRotate}>
                  <p >+</p>
                </div>


              </>



            }
            {/* <div className={styles.videoContainer}  onClick={handleRotate}>
<video width="340" height="160" controls className={styles.videoElement}>
<source src={videoSource} type="video/mp4" />
Your browser does not support the video tag.
</video>
</div> */}
          </div>
        </div>

        <div className={`${styles.content} ${styles.phoneContentBelow}`}></div>
        <div className={`${styles.content} ${styles.ourQuantity}`}>
          <div className={styles.leftImage}>
          <div className={styles.belowYellowGradientsContainer}>
                
                {/* <img src={bg} alt='leftYellowGradient' className={styles.leftYellowGradients}></img> */}
  
                 </div>
            <img src={animalsBird} alt='animalsBird'></img>
          </div>
          <div className={styles.rightContent}>
            <p className={`text_Acron ${styles.prideText}`}>We take pride in our numbers!</p>
            <div className={styles.numbersContainer}>
              <div className={styles.prideContent}>
                <p className={`text_Acron ${styles.prideTextUpper}`}>24</p>
                <p className={`text_Cabinet ${styles.prideTextLower}`}>Location
                  presence</p>

              </div>
              <img src={verticalLine} alt='verticalLine'></img>
              <div className={styles.prideContent}>
                <p className={`text_Acron ${styles.prideTextUpper}`}>100+</p>
                <p className={`text_Cabinet ${styles.prideTextLower}`}>Healthcare
                  centers</p>

              </div>
              <img src={verticalLine} alt='verticalLine' style={{height:"auto;"}}></img>

              <div className={styles.prideContent}>
                <p className={`text_Acron ${styles.prideTextUpper}`}>1K+</p>
                <p className={`text_Cabinet ${styles.prideTextLower}`}>Customers
                  Served</p>

              </div>
            </div>
          </div>

        </div>

        <div className={`${styles.content} ${styles.healthCareContent}`}>
          <div className={styles.serviceProvided}>
            <img src={updownImage} alt='updownImage'></img>
            <p className='text_Cabinet' style={{fontWeight:'bold'}}>Our Healthcare Provider</p>
            <img src={updownImage} alt='updownImage'></img>

          </div>
          <div className={styles.healthCareProviders}>
          <div className='styles.serviceImage'>
            {partnerImages.map((image, index) => (
            <img key={index} src={image} alt={`Service ${index}`} className={styles.partnerImage} />
          ))}



            </div>
            <div className={styles.partnerBtn}>
              <Button onClick={onClickPartner}>
                <p style={{ fontWeight: 'bold' }}>Partner with us</p>
              </Button>
            </div>
          </div>
        </div>

        <div className={`${styles.content} ${styles.addressImageContent}`}>
          <div className={styles.addressImage}>
            {/* <img src={addressImage} alt='addressImage'></img> */}
            <MapComponent /> 
           
          </div>

        </div>
        <div className={styles.contactContainer}>
                 

                 <p className={`text_Cabinet ${styles.address}`}><img src={loc} className={`${styles.adressImg}`} alt='location'></img>Sushant lok 3, sector 58, Gurugram</p>
                 <p className={`text_Cabinet  ${styles.address}`} ><img src={mail} alt='mail' className={`${styles.adressImg}`}></img>   care@medidhan.com</p>
                 <p className={`text_Cabinet  ${styles.address}`} > <img src={phone} alt='phone' className={`${styles.adressImg}`}></img>+91 9911401208</p>
               </div>
        <div className={`${styles.content} ${styles.footerContainer}`}>
          <Footer ></Footer>
        </div>

      </div>

    </div>
  );
}

export default LandingPage;
