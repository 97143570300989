import styles from './Buttons.module.css';
import arrow_back from "../images/arrow_back.png"
export const SendOptButton = () => {
  return (
    <button className={styles.continue_button}>
      <p>Send OTP</p>
    </button>
  );
};

export const BackButton = ({onClick}) => {
  return (
    <div className={styles.back_button} onClick={onClick}>
            <span style={{ cursor: 'pointer', display: 'inline-block', background: 'transparent' }} >
            <img src={arrow_back} alt='arrow_back' style={{ width: '32px', height: '32px' }} />
            </span>
   </div>
  );
};
 
 

export const ContinueButton = () => {
  return (
    <button className={styles.continue_button} >
      <p>Continue</p>
    </button>
  );
};

export const Button = ({ onClick, children }) => {
  return (
    <button onClick={onClick}>
      {children}
    </button>
  );
}
 