import React, { useEffect, useState } from 'react';
import styles from '../../common/LandingPage/LandingPage.module.css'; // Adjust the path as needed

const AnimatedText = () => {
  const texts = ["0% Interest", "0 Downpayment", "0 Processing Fee"];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentTextIndex((prev) => (prev + 1) % texts.length);
        setFade(true);
      }, 900); // Time it takes to fade out
    }, 1500); // Total cycle time (1 second fade out + 1 second text change + 1 second fade in)

    return () => clearInterval(interval);
  }, []);

  return (
    <p
      className={`text_Cabinet ${styles.p2} ${fade ? styles.fadeIn : styles.fadeOut}`}
      style={{ fontWeight: 'bold' }}
    >
      {texts[currentTextIndex]}
    </p>
  );
};

export default AnimatedText;
