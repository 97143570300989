import React, { useState, useEffect, useRef } from 'react';
import styles from "./Service.module.css"
import { Navigate, useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { Box, styled } from '@mui/material'; // Import Box and styled from MUI
import bg from '../../common/images/bg.png';
import logoHospital from '../../common/images/logoHospital.png';
import photoHospital from '../../common/images/photoHospital.png';
import arrow_back from '../../common/images/arrow_back.png';
import arthopaedicSVG from '../../common/images/arthopaedic.svg';
import Button from '../Buttons/Buttons';

import Orthopaedic from "../../common/images/orthopaedicReplacement.png"
import generalSurgery from "../../common/images/generalSurgery .png"
import ivf from "../../common/images/ivf.png";
import rootCanal from "../../common/images/rootCanal.png";
import dentalRestoration from "../../common/images/dentalRestoration.png"
import fertility from "../../common/images/fertility.png"
import hairTransplant from "../../common/images/hairTransplant.png"
import cryotherapy from "../../common/images/cryotherapy.png"



const Service = () => {
  const Navigate = useNavigate();

  const [services, setServices] = useState([
    { id: 2, name: "Orthopaedic replacement", logo: Orthopaedic },
    { id: 3, name: "General surgery ", logo: generalSurgery },
    { id: 4, name: "IVF", logo: ivf },
    { id: 5, name: "Root canal", logo: rootCanal },
    { id: 6, name: "Dental restoration", logo: dentalRestoration },
   
    { id: 7, name: "Fertility ", logo: fertility },
    { id: 9, name: "Hair transplant", logo: hairTransplant },
   
    { id: 10, name: "Cryotherapy", logo: cryotherapy },
    
  ]);

  const [boxClicked, setBoxClicked] = useState(false);
  const [clickedService, setClickedService] = useState(null);

  const handleServiceClick = (id) => {
    setClickedService(id);
    setBoxClicked(true);
  };
 const onClick = async() => {
    console.log(localStorage)
const userId = localStorage.getItem("userId");
console.log(userId);
console.log(clickedService)
const serviceName = services[clickedService-1].name;
console.log(serviceName);
const response = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/user/api/saveServices",{
    method:'post',
    headers:{'Content-Type':'application/json'},
    body:JSON.stringify({serviceName: serviceName ,userID : userId})
})
console.log(response)

Navigate("/user/loanApproved", { state: { service_name: serviceName } });

 }

  return (
    <div className={styles.container}>
      <div className={styles.details}>

        <div className={styles.background_image}>
          <img src={bg} alt='bg' />
          <img src={bg} alt='bg' />
        </div>

        <div className={styles.content}>
          <p className={styles.p1}>Hey,
            <p className={styles.name}>Arvind
              <span className={styles.hand}>👋</span>
            </p>
          </p>
          <p className={styles.p2}>Your service provider is</p>
          <img src={logoHospital} alt='Hospital Logo' className={styles.logo} />
          <span className={styles.p3}>Narayana Hospital</span>
          <div className={styles.photoHospital}>
            <img src={photoHospital} alt='photoHospital' />
          </div>
        </div>
      </div>
      <p className={`${styles.text} ${styles.p2}`}>Select your required service</p>

      <div className={styles.services}>
        {services.map(service => (
          <div
            key={service.id}
            className={`${styles.serviceBox} ${clickedService === service.id ? styles.clicked : ''}`}
            onClick={() => handleServiceClick(service.id)}
          >
            <div className={styles.serviceImage}>
              <img src={service.logo} alt="Arthopaedic" className={styles.vectorImage} />
            </div>
            
            <div className={styles.serviceName}>
              <p className={`${styles.p3} `}>{service.name}</p>
            </div>
          </div>
        ))}

      </div>

      {
        boxClicked && 
        <button className={styles.continue_button} onClick={onClick} > <p>Continue</p> </button>

         
      }
    </div>

  );
};

export default Service;