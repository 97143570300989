import React, { useState,useEffect } from 'react';
import styles from './Age.module.css';
import logoBlue from '../../common/images/logo_blue.png';
import headerContent from '../../common/images/headerContent.png';
import arrow_forward from '../../common/images/arrow_forward.png';
import { Button } from "../Buttons/Buttons";
import { useNavigate } from 'react-router-dom';


const AccountSetup = ({videoSrc,recording}) => {
  const Navigate = useNavigate();
  const [age, setAge] = useState('');
  const email = localStorage.getItem('email');
  useEffect(()=>{
    setVideo();
   },[])
   const setVideo = ()=>{
    const video = document.getElementById("videoElement");
    console.log(video)
    if (video) {
      video.srcObject = videoSrc;
    }
   }
  const onClickNextAge = async() => {

    const response = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/hospital/api/saveage",{
      method:'post',
      headers:{'Content-Type':'application/json'},
      body:JSON.stringify({"age": age,"email":email})
    })
    if(response.ok)
       Navigate('/hospital/hospital');
  };

  return (
    !recording ? (<div><h1>Recording stopped can't continue. Please restart video kyc to continue</h1></div>):
    (
    <div className={styles.main_container}>
      <div className={styles.header_container}>
        <img src={logoBlue} alt='logoBlue' className={styles.logo_blue} />
        <img src={headerContent} alt='headerContent' className={styles.headerContent} />
      </div>

      <div className={styles.content_container}>
       
          <div className={styles.content}>
            <p className={`text_Acron ${styles.heading}`}>Help us understand your practice better</p>
            <div className={`text_Acron ${styles.text}`}>
              <span className={styles.first_text}>2 of 6 </span>
              <span>
                <img src={arrow_forward} alt='arrow_forward' className={styles.arrow_forward}></img>
              </span>
              <span className={styles.second_text}>Age of practice</span>
            </div>
            <div className={`${styles.input_container} ${styles.input_container_age} `}>
              <input
                maxLength={3}
                type="numeric"
                placeholder="| 0" 
                 
                className={`text_Acron ${styles.input_field} ${styles.input_age}`}
                value={age}
                onChange={(e) => setAge(e.target.value)}
              />
                {/* <video id="videoElement" autoPlay muted className={styles.video} src={videoSrc}></video> */}
              <span className={`text_Cabinet ${styles.years}`}>years</span>
            </div>
            <div className={styles.button_container}>
              <Button onClick={onClickNextAge} className={styles.next_button}>
                Next
              </Button>
            </div>
          </div>
          <div className={styles.right_content}>
        <video id="videoElement" autoPlay muted className={styles.video} src={videoSrc}></video>
        </div>
      </div>
    </div>
    )
  );
};

export default AccountSetup;
