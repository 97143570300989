import React, { useState, useEffect } from 'react';
import styles from "./Dashboard.module.css"
import { useNavigate } from 'react-router-dom';
import logo from "../images/logo.png"
import profilePhoto from "../images/profilePhoto.png";
import profileEditor from "../images/profileEditor.png";
import logoHospital from "../images/logoHospital.png"
import ApplyForAnotherLoan from "../images/ApplyForAnotherLoan.png"
import ApplyForTopUp from "../images/ApplyForTopUp.png"
import { Button } from '../Buttons/Buttons';
import GoogleTranslate from '../Translate/Googletranslate';

const Dashboard = () => {
  const Navigate = useNavigate();
  const userID = localStorage.getItem("userId");

  console.log(userID)
  const [accecpted, setAccepted] = useState(false);
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [fullname,setFullName] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [hospitalName,setHospitalName] = useState('');
  const [profilePic,setProfilePic] = useState(profilePhoto)
  const getUserData = async () => {
    try {
      const response = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/user/api/getUserData", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userID: userID })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const user = await response.json();
      console.log(user)
      console.log(user.User.name)
      const {name,profilePicUrl,hospitalName} = user.User;
      console.log(name)

      // // Extract the first name from the full name
      
      const firstName = name.split(' ')[0];
      

      setUserData(user);
      setFirstName(firstName);
      setFullName(name)
      setProfilePic(profilePicUrl)
      setHospitalName(hospitalName)
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const onClickOpen = () => {
    setOpen(!open);
  }

  const accecptance = () => {
    setAccepted(!accecpted);
  }
  const onClickProfile = () => {
    Navigate('/user/userProfile',{state:{fullname}})
  }
  const onClickNext = () => {
    Navigate('/user/finalVerification')
  }

  return (
    <div className={styles.container}>
      <div className={styles.details}>
      {/* <GoogleTranslate/> */}

        <div className={styles.logoContainer}>
          <img src={logo} alt='logo' />
          <div className={styles.ProfileContainer} onClick={onClickProfile}>
            <img src={profilePic} alt='profilePhoto' className={styles.profilePhoto} />
            <img src={profileEditor} alt='profileEditor' className={styles.profileEditor} />
          </div>

        </div>
        <p className={`text_Acron ${styles.p1}`}>Hey,<span>{firstName} 👋</span></p>

      </div>


      <div className={styles.interestEMIBox}>

        <div className={styles.identity_text}>
          <img src={logoHospital} alt='Hospital Logo' className={styles.logo} />
          <p className={`text_Acron ${styles.p3}`}>{hospitalName}</p>
        </div>

        <div className={styles.boxContainer}>
          <div className={styles.interestEMI}>
            <p className={`text_Acron ${styles.upperText}`}>Loan amount</p>
            <p className={`text_Cabinet ${styles.lowerText}`}>₹5,00,000</p>

          </div>
          <div className={styles.interestEMI}>
            <p className={`text_Acron ${styles.upperText}`}>Sanction date</p>
            <p className={`text_Cabinet ${styles.lowerText}`}>Feb 21, 2024</p>

          </div>
        </div>
      </div>

      <div className={styles.lowerContainer}>

        <span className={`text_Acron ${styles.repayText}`}>Repayments </span>
        <span className={`text_Acron ${styles.repayText} ${styles.open}`} onClick={onClickOpen}> {open ? '-' : '+'}</span>
        
           <div className={`${styles.repayments} ${!open ? styles.repayments2 : ''}`}>
            <div className={styles.box}>
              <div className={styles.left}>
                <p className='text_Cabinet'>March 21, 2024</p>
                <p className='text_Cabinet'>Upcoming</p>
              </div>
              <div className={styles.right}>
                <p className='text_Cabinet'>₹1,66,666</p>
              </div>

            </div>
            <div className={styles.box}>
              <div className={styles.left}>
                <p className='text_Cabinet'>April 21, 2024</p>
                <p className='text_Cabinet'>Upcoming</p>

              </div>
              <div className={styles.right}>
                <p className='text_Cabinet'>₹1,66,666</p>
              </div>
            </div>
            <div className={`${styles.box} ${styles.lowerBox}`}>
              <div className={styles.left}>
                <p className='text_Cabinet'>May 21, 2024</p>
                <p className='text_Cabinet'>Upcoming</p>

              </div>
              <div className={styles.right}>
                <p className='text_Cabinet'>₹1,66,666</p>
              </div>
            </div>

          </div>
        


      </div>
      <div className={`${styles.applyFor} ${!open ? styles.applyFor2 : ''}`}>

        <img src={ApplyForTopUp} alt='ApplyForTopUp'></img>
        <img src={ApplyForAnotherLoan} alt='ApplyForAnotherLoan'></img>

      </div>



    </div>
  );
};

export default Dashboard;
