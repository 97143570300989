import React, { useState, useRef, useEffect } from 'react';
import styles from './Login.module.css';
import OtpInput from "otp-input-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import backgroundImage from "../../common/images/bg.png"; // Import the background image
import logo1 from "../../common/images/logo1.png";
import photoDoctors from "../../common/images/photoDoctors.png";
import { useNavigate } from 'react-router-dom';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from '../../../firebase';
import MobileVerification from "../Mobileverification/MobileVarification";
import GoogleTranslate from '../Translate/Googletranslate';
import usePermissions from '../../../hooks/usePermission';

const Login = () => {
  const [hospitalName, setHospitalName] = useState('');
  const [logo, setLogo] = useState(null);
  const [location, setLocation] = useState('');
  const [ph, setPh] = useState('');
  const [showPlaceholder, setShowPlaceholder] = useState(true); // State to control the visibility of the placeholder
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false); // State to track whether OTP has been sent
  const recaptchaVerifierRef = useRef(null); // Ref to store the RecaptchaVerifier instance
  const [captchaVisible, setCaptchaVisible] = useState(false);
  const [languageSelected, setLanguageSelected] = useState(localStorage.getItem("lang") || false);
  const [lang, setLang] = useState(localStorage.getItem("lang") || '');
  const { permissionsGranted, requestPermissions } = usePermissions(); 
  const[permissiongiven,setPermissionGiven] = useState(false);

  useEffect(() => {
    // localStorage.clear();
 
    fetchData();
    handleLoginClick();
  }, []);

  useEffect(() => {
    // Load Google Translate API script
    const script = document.createElement('script');
    script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    script.async = true;
    document.body.appendChild(script);

    window.googleTranslateElementInit = () => {
      const translator = new window.google.translate.TranslateElement(
        { pageLanguage: 'en' },
        'google_translate_element'
      );

      const goog = document.getElementsByClassName('goog-te-combo');
      if (goog[0]) {
        goog[0].addEventListener('change', function (event) {
          const selectedValue = event.target.value;
          setLanguageSelected(true);
          setLang(selectedValue);

         localStorage.setItem("lang",languageSelected);
        });
      }
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/user/getqrdata');
      if (!response.ok) {
        throw new Error('Failed to fetch QR code data');
      }
      const data = await response.json();
      const urlParams = new URLSearchParams(data.url);
      const name = decodeURIComponent(urlParams.get('Name'));
      const loc = decodeURIComponent(urlParams.get('location'));
      const logoH = decodeURIComponent(urlParams.get('logo'));
      setHospitalName(name);
      setLogo(logoH);
      setLocation(loc);
    } catch (error) {
      console.error('Error fetching QR code data:', error);
    }
  };

  const createRecaptchaVerifier = async () => {
    if (!recaptchaVerifierRef.current) {
      recaptchaVerifierRef.current = new RecaptchaVerifier('recaptcha', {
        'size': 'invisible',
      }, auth);
    }
    return recaptchaVerifierRef.current;
  };

  const sendOtp = async () => {
    try {
      const recaptchaVerifier = await createRecaptchaVerifier();
      const confirmationResult = await signInWithPhoneNumber(auth, ph, recaptchaVerifier);
      const response = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/user/savePhone", {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phone: ph })
      });
      if (!response.ok) {
        throw new Error('Failed to save phone number');
      }
      setUser(confirmationResult);
      setCaptchaVisible(true);
      setOtpSent(true);
      alert("OTP has been sent!");
    } catch (err) {
      if (err.code === 'auth/timeout') {
        console.error('Timeout error:', err.message);
      } else {
        console.error('Error:', err);
      }
    }
  };

  const handleChange = (value) => {
    setPh('+' + value);
    if (recaptchaVerifierRef.current) {
      recaptchaVerifierRef.current.clear();
    }
    setShowPlaceholder(value === '' || value === '+' ? true : false);
  };

  // const handleLanguageSelect = (language) => {
  //   setLanguageSelected(true);
  //   setLang(language);
  //   // localStorage.setItem("lang", language);
  //   localStorage.clear()
    
  // };
  // console.log(languageSelected)
  // console.log(localStorage)
const onClick = ()=>{
  // localStorage.clear();
  setLanguageSelected(true);
  // window.location.reload();
}

const handleLoginClick = async () => {
  const permissionsGranted = await requestPermissions();
  console.log(permissionsGranted.location)
  if (permissionsGranted) {
    setPermissionGiven(true);
  } else {
    alert('All permissions are required to proceed.');
  }
};
localStorage.setItem("lang",languageSelected);

console.log(languageSelected)





  return (
    <>
      {!languageSelected ? (
        <>
        <GoogleTranslate  />
        <p className={`text_Acron ${styles.orparagraph}`}>Or</p>

        <div className={styles.defaultLanguage}>
          
        <button className={styles.continue_button} onClick={onClick}>
            <p>Continue with English</p>
          </button>
          {/* <p className='text_Cabinet'>Cointune With English</p> */}
        </div>
        </>
      ) : (
        <>
        
          {otpSent && permissiongiven  ? (
            <MobileVerification user={user} phone={ph} hospitalName={hospitalName} location={location} />
          ) : (
            <div className={styles.main_container}>
              <section className={styles.login_container}>
                <div className={styles.img1}>
                  <img src={backgroundImage} alt='bg' />
                </div>
                <div className={styles.div1}>
                  <div className={styles.content}>
                    <div className={styles.logo_container}>
                      <img src={logo1} alt='logo1' />
                    </div>
                    <div className={styles.logoHospital_container}>
                      <img src={`https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/user/${logo}`} alt="Logo" />
                    </div>
                    <div className={styles.name_container}>
                      <p>{hospitalName}</p>
                    </div>
                    <div className={styles.address_container}>
                      <p>{location}</p>
                    </div>
                  </div>
                </div>
                <div className={styles.div2}>
                  <div className={styles.content2}>
                    <img src={photoDoctors} alt='logofitness' />
                  </div>
                </div>
              </section>

              <div className={styles.form_container}>
                <label className={styles.form_label}>Login/Signup</label>
                <div className={styles.phone_input_container}>
                  <PhoneInput
                    country={"in"}
                    value={ph}
                    onChange={handleChange}
                    placeholder={showPlaceholder ? "Enter mobile number" : ""}
                    inputStyle={{ width: '100%', height: '70px' }}
                    containerStyle={{ marginTop: '10px' }}
                  />
                  {showPlaceholder && <span className={styles.phone_input_placeholder}><p>Enter mobile number</p></span>}
                  <div className={styles.overlay}>
                    <div className={styles.captcha_container}>
                      <div id="recaptcha" className={styles.recaptcha_container}></div>
                    </div>
                  </div>
                </div>
                <div className={styles.login_btn_container}>
                  <button onClick={sendOtp} className={styles.btn_login}>Let’s get started</button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Login;
