import { React } from 'react';
import styles from './AboutUs.module.css'; // Import the CSS file
import logo2 from '../images/logo2.png';
import { Button } from '../../HospitalComponents/Buttons/Buttons';
import logo from '../images/logo_blue.png';
import { useNavigate } from 'react-router-dom';

const Home = ({ toggleForPatients }) => {
  const Navigate = useNavigate();

  const clickForPatients = () => {
    Navigate('/forPatient');
    
  };

  const clickForAbout = () => {
    Navigate('/aboutUs');
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          <img src={logo} alt="logo" />
          <ul>
            <li><a href="/" className="text_Cabinet" rel="noopener noreferrer">Home</a></li>
            <li><a href="/user/" className="text_Cabinet" rel="noopener noreferrer">For Patients</a></li>
            <li><a href="/hospital/" className="text_Cabinet" target="_blank" rel="noopener noreferrer">For Partners</a></li>
            <li><a href="/aboutUs" className="text_Cabinet" target="_blank" rel="noopener noreferrer">About</a></li>
          </ul>
        </div>
      </div>
      <div className={styles.content_container}>
        <div className={`${styles.content} ${styles.aboutContainer}`}>
          <img src={logo2} alt="logo2" className={styles.logo2} />
          <p className={`text_Cabinet ${styles.text1}`} style={{ color: 'white' }}>
            Empowering Health, Financing,<span style={{ fontWeight: 'bold' }}>Hope</span> <span style={{ fontStyle: 'italic', fontFamily: 'ilatics, Arial, sans-serif' }}>Healthcare Financing</span>
          </p>
          <div className={styles.aboutInfo} style={{ color: 'white' }}>
            <p className={`text_Cabinet ${styles.para2}`} style={{ color: 'white', fontSize: '2.4rem', lineHeight: '3rem' }}>
              A fintech company providing specialised 0% EMI option for all your medical needs.<br />
              Our mission is to alleviate financial burdens during healthcare situations, offering fast and flexible access to funds.
            </p>
            <div className={styles.joinUs}>
              <p className="text_Cabinet" style={{ color: 'white', fontSize: '2.4rem' }}>Join us on this mission of compassion and innovation!</p>
              <div className={styles.footerBtnContainer}>
                <div className={`${styles.footerBtn} ${styles.applyFooterBtn}`}>
                  <Button>
                    <p style={{ fontWeight: 'bold' }}> Apply for 0% finance</p>
                  </Button>
                </div>
                <div className={`${styles.footerBtn} ${styles.partnerFooterBtn}`}><Button>Partner with us</Button></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
