import React, { useState,useEffect } from 'react';
import styles from './BankDetails.module.css';
import logoBlue from '../../common/images/logo_blue.png';
import headerContent from '../../common/images/headerContent.png';
import arrow_forward from '../../common/images/arrow_forward.png';
import { Button, BackButton } from "../Buttons/Buttons";
import { useNavigate } from 'react-router-dom';
import { styled, TextField as MuiTextField } from '@mui/material';
import IdentityVarified from '../Identity/IdentityVarified';
import crossBackWhite from "../../common/images/crossBackWhite.png";

const StyledTextField = styled(MuiTextField)`
  width: 49%;
  height: 7rem;
  
  background-color:  rgba(255, 255, 255, 1);
  border-radius: 1.6rem
;
  object-fit: cover;
  &::before {
      border-bottom: none; /* Remove border just below the label */
    }
  label {
   font-family: "CabinetGrotesk-variable";
    width:23.2rem;
    height: 1.6rem;
    top: .6rem;
    left: 1.6rem;
    pointer-events: none;
    color: rgba(0, 0, 0, 1);
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.682rem;
    letter-spacing: 0.08em;
    opacity: 50%;
    &.Mui-focused {
      top: 1.6rem;
      // Other focused label styles...
    }
    &.MuiInputLabel-shrink {
      top: 1.6rem; // Keep the label at the top position when not focused
    }
  }
  label.Mui-focused {
    width: 23.2rem;
    height:1.6rem;
    top: 1.6rem;
    left: 1.6rem;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.482rem;
    color: rgba(0, 0, 0, 1);
    letter-spacing: 0.08em;
    
  }

 

  .MuiInput-underline  {
    border: none;
  }
 
`;


const BankDetails = ({videoSrc,mediaRecorder,recording}) => {
  const Navigate = useNavigate();
  const [patients, setPatients] = useState('');
  const [verified, setVerified] = useState(false);
  const [bankName, setBankName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [nameAsPerPassbook, setNameAsPerPassbook] = useState('');
  const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
  const email = localStorage.getItem('email');
  const [mobile, setMobile] = useState('');

  const [videoElement,setVideoElement] = useState(null)
  useEffect(()=>{
    setVideo();
   },[])
   const setVideo = ()=>{
    const video = document.getElementById("videoElement");
    console.log(video)
    if (video) {
      video.srcObject = videoSrc;
      setVideoElement(video)
    }
   }
  const handleBankNameChange = (event) => {
    setBankName(event.target.value);
  };

  const handleAccountNumberChange = (event) => {
    setAccountNumber(event.target.value);
  };

  const handleConfirmAccountNumberChange = (event) => {
    setConfirmAccountNumber(event.target.value);
  };

  const handleIfscCodeChange = (event) => {
    setIfscCode(event.target.value);
  };

  const handleNameAsPerPassbookChange = (event) => {
    setNameAsPerPassbook(event.target.value);
  };

  const handleMobileNumberChange = (event) => {
    setMobile(event.target.value);
  };


  const onClickNextPatients = async() => {
    try {
      console.log("hi")
    // console.log(formData)
    


      if(accountNumber !== confirmAccountNumber){
      alert("account number not matched ");
      }else{
        let bankAccountDetails = [];
        bankAccountDetails.push({
         accountNumber: accountNumber,
         ifscCode: ifscCode,
         accountHolderName:nameAsPerPassbook,
         mobile:mobile
        });
      const response = await fetch('https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/hospital/api/saveBankInfo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          // video:formData.video,
        bankAccountDetails:bankAccountDetails,

          email:email
        })
      });
  console.log(response)
      if (response.ok) {
        const data = await response.json();
        console.log(data); // Do something with the response from the backend
        alert("bank information verifued successfully ")
        await mediaRecorder.stop();
        setVerified(true);
      } else {
        console.error('Failed to save bank information');
        alert("Invalid Bank info")
      }
    }
    } catch (error) {
      console.error('Error:', error);
      alert("Invalid bank credential")
    }
    
  };
  
  
  
  
  const onClickBack = () => {
    Navigate('/hospital/service');
  }
 const onClickBackCross = async() => {
  
    Navigate('/hospital/dashboard');
   
  setVerified(false);

 }
  


  return (

    !recording && !verified? (<div><h1>Recording stopped can't continue. Please restart video kyc to continue</h1></div>):
    (
    <div className={styles.main_container}>
      <div className={styles.header_container}>
        <img src={logoBlue} alt="logoBlue" className={styles.logo_blue} />
        <img
          src={headerContent}
          alt="headerContent"
          className={styles.headerContent}
        />
      </div>

      <div className={styles.content_container}>
        <div className={styles.content}>
          <div className={styles.heading}>
            <BackButton onClick={onClickBack}></BackButton>
          </div>
          <div className={`text_Acron ${styles.text}`}>
            <span className={styles.first_text}>6 of 6 </span>
            <span>
              <img
                src={arrow_forward}
                alt="arrow_forward"
                className={styles.arrow_forward}
              ></img>
            </span>
            <span className={styles.second_text}> Link bank account</span>
          </div>
          <div className={styles.input_container}>
            <StyledTextField
              id="standard-basic"
              label="Enter your account number"
              variant="standard"
              onChange={handleAccountNumberChange}
              inputProps={{ inputMode: "numeric" }}
              InputProps={{ disableUnderline: true }}
              sx={{
                input: {
                  color: "rgba(0, 0, 0, 1)",
                  position: "relative",
                  fontFamily: "CabinetGrotesk-variable",
                  top: "1.0rem",
                  left: "1.6rem",
                  pointerEvents: "none",
                  fontSize: "1.6rem",
                  fontWeight: "400",
                  lineHeight: "1.482rem",
                  letterSpacing: "0.08em",
                },
              }}
              fullWidth
            />
            <StyledTextField
              id="standard-basic"
              label="Confirm account number"
              variant="standard"
              onChange={handleConfirmAccountNumberChange}
              inputProps={{ inputMode: "numeric" }}
              InputProps={{ disableUnderline: true }}
              sx={{
                input: {
                  color: "rgba(0, 0, 0, 1)",
                  position: "relative",
                  fontFamily: "CabinetGrotesk-variable",
                  top: "1.0rem",
                  left: "1.6rem",
                  pointerEvents: "none",
                  fontSize: "1.6rem",
                  fontWeight: "400",
                  lineHeight: "1.482rem",
                  letterSpacing: "0.08em",
                },
              }}
              fullWidth
            />
            <StyledTextField
              id="standard-basic"
              label="IFSC code"
              variant="standard"
              onChange={handleIfscCodeChange}
              inputProps={{ inputMode: "numeric" }}
              InputProps={{ disableUnderline: true }}
              sx={{
                input: {
                  color: "rgba(0, 0, 0, 1)",
                  position: "relative",
                  fontFamily: "CabinetGrotesk-variable",
                  top: "1.0rem",
                  left: "1.6rem",
                  pointerEvents: "none",
                  fontSize: "1.6rem",
                  fontWeight: "400",
                  lineHeight: "1.482rem",
                  letterSpacing: "0.08em",
                },
              }}
              fullWidth
            />
            <StyledTextField
              id="standard-basic"
              label="Name as per bank passbook"
              variant="standard"
              onChange={handleNameAsPerPassbookChange}
              inputProps={{ inputMode: "numeric" }}
              InputProps={{ disableUnderline: true }}
              sx={{
                input: {
                  color: "rgba(0, 0, 0, 1)",
                  position: "relative",
                  fontFamily: "CabinetGrotesk-variable",
                  top: "1.0rem",
                  left: "1.6rem",
                  pointerEvents: "none",
                  fontSize: "1.6rem",
                  fontWeight: "400",
                  lineHeight: "1.482rem",
                  letterSpacing: "0.08em",
                },
              }}
              fullWidth
            />
            <StyledTextField
              id="standard-basic"
              label="Enter Mobile number"
              variant="standard"
              onChange={handleMobileNumberChange}
              inputProps={{ inputMode: "numeric" }}
              InputProps={{ disableUnderline: true }}
              sx={{
                input: {
                  color: "rgba(0, 0, 0, 1)",
                  position: "relative",
                  fontFamily: "CabinetGrotesk-variable",
                  top: "1.0rem",
                  left: "1.6rem",
                  pointerEvents: "none",
                  fontSize: "1.6rem",
                  fontWeight: "400",
                  lineHeight: "1.482rem",
                  letterSpacing: "0.08em",
                },
              }}
              fullWidth
            />
          </div>
          <div className={styles.button_container}>
            <Button
              onClick={onClickNextPatients}
              className={styles.next_button}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
      {verified && (
        <div className={styles.overlay}>
          <div className={styles.identity}>
            <div className={styles.crossBack} onClick={onClickBackCross}>
              <img src={crossBackWhite} alt="crossBackWhite" />
            </div>
            <IdentityVarified text="Your bank account has been linked successfully" />
          </div>
          {/* You can style this overlay div with a background color and blur effect */}
          <div className={styles.blur_effect}></div>
        </div>
      )}
      <div className={styles.right_content}>
        <video
          id="videoElement"
          autoPlay
          muted
          className={styles.video}
          src={videoSrc}
        ></video>
      </div>
    </div>
)
  );

};

export default BankDetails;
