import React,{useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './IdentityVarified.module.css'; // Import the CSS file
import varifiedAnimation from '../../common/images/varifiedAnimation.json';
import Lottie from "lottie-react"
import bg from '../../common/images/bg.png';

const IdentityVarified = ({text}) => {
  console.log(text)
const navigate = useNavigate();
 
  return (
    <div className={styles.identity_container}>
        <div className={styles.background_image }>
        <img src={bg} alt='bg' />
        {/* <img src={bg} alt='bg' /> */}
      </div>
     
      
      
        <div className={styles.identity_logo}>
         <Lottie className={styles.lottie} animationData={varifiedAnimation}/> 
        </div>

     <div className={styles.identity_text}>
      {text !=="null" ? (<p>{text}</p>):(
        <p>Your identity has been verified</p>
      ) }
     
     </div>
            
      </div>
     
       
    
  );
}

export default IdentityVarified;