import { useState } from 'react';

export  const useRecording = () => {
   const [videosrc, setVideoSrc] = useState(null);
   const [isRecording,setIsRecording] = useState(false)
   const [permission,setPermission] = useState(false)
  //  const [recording ,setRecording] = useState(false)
  
  const [mediaRecorder,setMediaRecorder]  =useState(null)
  const startRecording = async () => {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        const videoElement = document.getElementById("videoElement");

        if (videoElement) {
            videoElement.srcObject = stream;
            setIsRecording(true);
            setVideoSrc(stream);
            setPermission(true)
            const mediaRecorder = new MediaRecorder(stream);
            setMediaRecorder(mediaRecorder)
             const recordedChunks = [];

            // // Event handler to capture data chunks
             mediaRecorder.ondataavailable = event => {
            //   console.log(mediaRecorder)
                recordedChunks.push(event.data);
            //     console.log(recordedChunks)
            //     // setChunks(recordedChunks)
            };

            // // Event handler to handle when recording stops
             mediaRecorder.onstop = () => {
              
         stopRecording(mediaRecorder, recordedChunks);
            };

            // Start recording
            mediaRecorder.start();
           
            // Display the video element
            // videoElement.style.display = "block";
         

        } else {
            console.error('Video element not found.');
        }
    } catch (error) {
        console.error('Error accessing media devices:', error);
    }
};
 const stopRecording = (mediaRecorder, recordedChunks) => {
 setIsRecording(false);
  
  mediaRecorder.stream.getTracks().forEach(track => track.stop());
  const recordedBlob = new Blob(recordedChunks, { type: 'video/webm' });

  // Use or save the recordedBlob as needed
  // For example, you can set it as the source of a video element
  const recordedUrl = URL.createObjectURL(recordedBlob);
  console.log(recordedBlob, recordedUrl);

  // Hide the video element
  
};

  return {
    
    startRecording,
    stopRecording,
    videosrc,
    setVideoSrc,
    mediaRecorder,
   
    permission,
    setPermission,
    setIsRecording,
    isRecording
  };
};

// export default useRecording;
