import React, { useEffect, useState } from 'react';

const CountdownTimer = ({ initialTime, onComplete }) => {
  const [timer, setTimer] = useState(initialTime);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      onComplete();
      clearInterval(interval);
    }
    
    return () => clearInterval(interval);
  }, [timer]);

  return (
    <span>
      {timer > 0 ? `Resend OTP in ${timer}s` : ''}
    </span>
  );
};

export default CountdownTimer;
