import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import styles from "./AadhaarVarification.module.css";
import arrow_back from "../images/arrow_back.png";
import { styled, TextField as MuiTextField } from '@mui/material';

const StyledTextField = styled(MuiTextField)`
  width: 100%;
  height: 50vh;
  object-fit: cover;
  &::before {
      border-bottom: none; /* Remove border just below the label */
    }
  label {
    font-family: "CabinetGrotesk-variable";
    width:222px;
    height: 16px;
    top: 6px;
    left: 16px;
    pointer-events: none;
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 16.82px;
    letter-spacing: 0.08em;
    opacity: 50%;
    &.Mui-focused {
      top: 16px;
      // Other focused label styles...
    }
    &.MuiInputLabel-shrink {
      top: 16px; // Keep the label at the top position when not focused
    }
  }
  label.Mui-focused {
    width: 222px;
    height:16px;
    top: 16px;
    left: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 14.82px;
    color: rgba(0, 0, 0, 1);
    letter-spacing: 0.08em;
    
  }

 

  .MuiInput-underline  {
    border: none;
  }
 
`;


const AadhaarVerification = (location) => {
  // const classes = useStyles();
  // console.log(location);
// const {phoneNumber} = location.state;
  const [aadhaarNumber, setAadhaarNumber] = useState('');
  const [aadhaar, setAadhaar] = useState(false);
  const [adharVerify,setAdharVerify] = useState(false);
  const [refId,setrefId] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const [sendOtp,setSendOtp]  = useState(false);

  // useEffect(() => {
  //   // Function to store Aadhaar number in local storage
  //   localStorage.setItem('aadhaarNumber', aadhaarNumber);
  // }, [aadhaarNumber]);

  const handleInputChange = (event) => {
    setAadhaarNumber(event.target.value);
  };



  const [otp, setOtp] = useState(['', '', '', '','','']);

  const otpInputs = useRef([]);
  const navigate = useNavigate();

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      otpInputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace') {
      e.preventDefault(); // Prevent default Backspace behavior
      if (otp[index] !== '') {
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
      } else if (index > 0) {
        otpInputs.current[index - 1].focus(); // Move cursor to the leftmost empty input box
      }
    }
  };
  const phoneNumber = localStorage.getItem("phone");
  const onClick = async () => {
    console.log(localStorage);
    const userId = localStorage.getItem("userId");
    console.log(phoneNumber);
  
    try {
      const response = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/user/api/saveAdharNumber", {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          aadhaar_number: aadhaarNumber,
          phone_number: phoneNumber,
          otp: otp,
          reference_id: refId
        })
      });
      
  
      const Json = await response.json();
      setIsVerifying(true);
  
      if (response.status === 200 && Json.message === "Aadhaar Card Exists") {
        localStorage.setItem("aadhaarNumber", aadhaarNumber);
        setAdharVerify(true);
        alert("Aadhaar Card verified");
      } else {
        alert(Json.message);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while verifying Aadhaar. Please try again.");
    }finally {
      setIsVerifying(false); // Set verifying state to false after the request is complete
    }
  };
  
  const onClickBack = () => {
    setAadhaar(false);
  };
  const onClickAadhaar = async() => {
    //  setAadhaar(true)
 setSendOtp(true)
     const response = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/user/api/sendOtpaddhar",{
       method:'post',
       headers:{'Content-Type':'application/json'},
       body:JSON.stringify({aadhaar_number:aadhaarNumber})
     })
     const json = await response.json();
     setSendOtp(false)
console.log(json)
setrefId(json)
     if(response.status === 200){
        alert("otp sent to the registered phone number");
        setAadhaar(true);
     }else if(response.status === 400){
      alert("invalid adhar number");
     }else{
      alert("error in saving! please try again");
     }
  };

   if(adharVerify){
    navigate("/user/identityVarified")
   }

  return (
    <div className={styles.container}>

      {aadhaar  ? (
        <>
          <div className={styles.back_button}>
            <span
              onClick={onClickBack}
              style={{ cursor: 'pointer', display: 'inline-block', background: 'transparent' }}
            >
              <img src={arrow_back} alt='arrow_back' style={{ width: '32px', height: '32px' }} />
            </span>
          </div>
          <div className={styles.paragraph_container_a}>
            <p className={styles.paragraph}>Verify your Aadhaar</p>
          </div>

          <div className={styles.otp_info}>
            <p>We have shared the OTP to your registered mobile number
            </p>
          </div>

          <div className={styles.otp_container}>
            {otp.map((value, index) => (
              <input
                key={index}
                ref={(el) => otpInputs.current[index] = el}
                type="text"
                className={styles.otp_input}
                placeholder="X"
                maxLength="1"
                value={value}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
              />
            ))}

          </div>
          {/* <div className={styles.resend_otp}>
            <p>Resend OTP in 10s</p>
          </div> */}
          <button  className={styles.continue_button} onClick={onClick} disabled={isVerifying}>
        {isVerifying ? 'Verifying...' : 'Verify'}
      </button>
        </>



      ) : (


        <>
          <div className={styles.back_button}>
            <span
              onClick={() => navigate("/AadhaarAndPan")}
              style={{ cursor: 'pointer', display: 'inline-block', background: 'transparent' }}
            >
              <img src={arrow_back} alt='arrow_back' style={{ width: '32px', height: '32px' }} />
            </span>
          </div>
          <div className={styles.paragraph_container}>
            <p className={styles.paragraph}>Verify your Aadhaar</p>
          </div>
          <div className={styles.aadhaarInput_container}>
            <StyledTextField

              id="standard-basic"
              label="Enter your Aadhaar number"
              variant="standard"
              onChange={handleInputChange}
              inputProps={{ inputMode: 'numeric' }}
              InputProps={{ disableUnderline: true }}
              sx={{
                input: {
                  color: 'rgba(0, 0, 0, 1)',
                  fontFamily: 'CabinetGrotesk-variable',
                  top: '20px',
                  left: '16px',
                  pointerEvents: 'none',
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '14.82px',
                  letterSpacing: '0.08em',
                }
              }}
              fullWidth
            />
          </div>
          <button className={styles.continue_button} onClick={onClickAadhaar} disabled={sendOtp}>
          <p>{sendOtp ? 'Sending Otp' : 'Send Otp'}</p>

          </button>
        </>
      )

      }

    </div>
  );
}

export default AadhaarVerification;
