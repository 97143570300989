import React, { useState, useEffect } from 'react';
import styles from './Hospital.module.css';
import logoBlue from '../../common/images/logo_blue.png';
import headerContent from '../../common/images/headerContent.png';
import arrow_forward from '../../common/images/arrow_forward.png';
import logoSearch from '../../common/images/logoSearch.png';
import { BackButton, Button } from "../Buttons/Buttons";

import { useNavigate } from "react-router-dom";
import crossBack from  "../../common/images/crossBack.png";
import logoLocation from "../../common/images/logoLocation.png";


const AccountSetup = ({ videoSrc,recording }) => {
  const Navigate = useNavigate();
  const [hospital, setHospital] = useState('');
  const [location, setLocation] = useState(false);
  const [address, setAddress] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null); // To store selected place details
  const [placePhoto, setPlacePhoto] = useState(null); // To store selected place photo
  useEffect(() => {
    setVideo();
  }, [])

  const setVideo = () => {
    const video = document.getElementById("videoElement");
    console.log(video)
    if (video) {
      video.srcObject = videoSrc;
    }
  }

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setHospital(value); // Update hospital state with user input

    // Make a request to Google Places API for autocomplete suggestions
    const response = await fetch("http://localhost:5000/hospital/places", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ query: value })
    });

    const jsondata = await response.json();
    setSuggestions(jsondata.predictions);
  };

  const fetchPlaceDetails = async (placeId) => {
    const response = await fetch('http://localhost:5000/hospital/getPlaceDetail',{
      method:"post",
      
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ placeId: placeId })
    })
    const data = await response.json();
    console.log(data);
    return data.result;
  };

  const fetchPlacePhoto = async (photoReference) => {
    try {
      const response = await fetch('http://localhost:5000/hospital/getPlacePhoto', {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ photoReference: photoReference })
      });
      const arrayBuffer = await response.arrayBuffer(); // Retrieve the ArrayBuffer response
      const base64Image = arrayBufferToBase64(arrayBuffer); 
      return `data:image/png;base64,${base64Image}`;
    } catch (error) {
      console.error('Error fetching place photo:', error);
      return null; // Return null in case of an error
    }
  };
  
  // Function to convert ArrayBuffer to base64
  function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }
  
  const onSelectAddress = async (selectedAddress) => {
    setAddress(selectedAddress);
    setLocation(true);
    const placeDetails = await fetchPlaceDetails(selectedAddress.place_id);
    // console.log(placeDetails)
    setSelectedPlace(placeDetails);
    if (placeDetails.photos && placeDetails.photos.length > 0) {
      const photoReference = placeDetails.photos[0].photo_reference;
      console.log(photoReference)
      const photoUrl = await fetchPlacePhoto(photoReference);
      console.log(photoUrl); // Ensure that photoUrl is not undefined
      setPlacePhoto(photoUrl);
    }
  };
  

  const onClickBack = () => {
    Navigate('/hospital/age');
  };

  const onClickBackCross = () => {
    setLocation(false);
  };

  const confirmLocation = async () => {
    alert("Your location has been confirmed");
    const email = localStorage.getItem('email');
    const hospitalName = selectedPlace.name;
    const hospitalAddress = selectedPlace.formatted_address;

    const response = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/hospital/api/saveHospitalinfo", {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "h_name": hospitalName, "h_loc": hospitalAddress, "email": email })
    });
    if (response.ok)
      Navigate('/hospital/service');
    else {
      alert("error")
    }
  };

  return (

    !recording ? (<div><h1>Recording stopped can't continue. Please restart video kyc to continue</h1></div>):
    (
    <div className={styles.main_container}>
      <div className={styles.header_container}>
        <img src={logoBlue} alt='logoBlue' className={styles.logo_blue} />
        <img src={headerContent} alt='headerContent' className={styles.headerContent} />
      </div>

      <div className={styles.content_container}>

        <div className={styles.content}>
          <div className={styles.heading}>
            <BackButton onClick={onClickBack}></BackButton>
          </div>

          <div className={`text_Acron ${styles.text}`}>
            <span className={styles.first_text}>3 of 6 </span>
            <span>
              <img src={arrow_forward} alt='arrow_forward' className={styles.arrow_forward}></img>
            </span>
            <span className={styles.second_text}>Select your hospital/clinic</span>
          </div>

          <div className={`${styles.input_container} ${address ? styles.new_class : ''}`}>
            <img src={logoSearch} alt='logoSearch' />
            <input
              type="text"
              placeholder="Search for your hospital or clinic"
              className={`text_Cabinet ${styles.input_field}`}
              value={hospital}
              onChange={(e) => handleSearchChange(e)}
              onClick={() => setAddress(true)} // Open address container on input click
            />
          </div>

          {suggestions && (
            <div className={styles.address_container}>
              {suggestions.map((address, index) => (
                <div key={index} className={styles.address_entry} onClick={() => onSelectAddress(address)}>
                  <img src={logoLocation} alt='logoLocation' className={styles.address_search_img} />
                  <span className={`text_Cabinet ${styles.address}`}>
                    <span className={styles.address_name}>{address.description}</span>
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {location && (
        <div className={styles.overlay}>
          <div className={styles.identity}>
            <div className={styles.crossBack} onClick={onClickBackCross} style={{ position: 'relative', left: '51rem', cursor: 'pointer' }}>
              <img src={crossBack} alt='crossBack' />
            </div>
            {selectedPlace && (
              <div className={styles.details}>
                <p className={`text_Acron ${styles.hospital_name}`}>{selectedPlace.name}</p>
                <p className={`text_Cabinet ${styles.hospital_address}`}>{selectedPlace.formatted_address}</p>
                {placePhoto && <img src={placePhoto} alt="Hospital" className={styles.hospital_image} />}
                <div className={styles.button_container}>
                  <Button onClick={confirmLocation} className={`${styles.next_button} ${styles.confirm_button}`} style={{ position: 'relative', top: '-0.3rem', right: '5rem' }}>
                    <p className='text_Cabinet'>Confirm location</p>
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className={styles.blur_effect}></div>
        </div>
      )}
      <div className={styles.right_content}>
        <video id="videoElement" autoPlay muted className={styles.video} src={videoSrc}></video>
      </div>
    </div>
    )
  );
};

export default AccountSetup;
