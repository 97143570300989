import React, { useState, useEffect, useRef } from 'react';
import { useNavigate,useLocation } from 'react-router';
import styles from "./PanVerification.module.css";
import arrow_back from "../../common/images/arrow_back.png";
import line from "../../common/images/line.png";
import logo from '../../common/images/logo.png';
import home_bg from '../../common/images/home_bg.png';
import logofitness from '../../common/images/logofitness.png';
import logoGoogle from "../../common/images/logoGoogle.png"
import { styled, TextField as MuiTextField } from '@mui/material';
import { BackButton, Button } from '../Buttons/Buttons';


// import CashfreeSDK from 'cashfree-sdk'; // Import Cashfree SDK
// import webpackConfig from '../../webpack.config';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
// // import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { TextField, LocalizationProvider, AdapterDateFns, DatePicker } from '@mui/lab';

import axios from 'axios';

const StyledTextField = styled(MuiTextField)`
  width: 100%;
  height: 50vh;
  object-fit: cover;
  &::before {
      border-bottom: none; /* Remove border just below the label */
    }
  label {
    font-family: "CabinetGrotesk-variable";
    position: relative;
    width:222px;
    height: 16px;
    top: 6px;
    left: 16px;
    pointer-events: none;
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 16.82px;
    letter-spacing: 0.08em;
    opacity: 50%;
    &.Mui-focused {
      top: 16px;
      // Other focused label styles...
    }
    &.MuiInputLabel-shrink {
      top: 16px; // Keep the label at the top position when not focused
    }
  }
  label.Mui-focused {
    position: relative;
    width: 222px;
    height:16px;
    top: 19px;
    left: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 14.82px;
    color: rgba(0, 0, 0, 1);
    letter-spacing: 0.08em;
    
  }

 

  .MuiInput-underline  {
    border: none;
  }
 
`;



const PanVerification = () => {
  // const classes = useStyles();
  const Navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const params = new URLSearchParams(search);
  console.log(params)
  let email = params.get('email');
  console.log(email)
  
  if (email !== null) {
    // Now you can use the email variable
    localStorage.setItem("email",email);
    console.log(email);
  } else {
    email  = location.state.email;
    console.log(email)

  }
  const [panNumber, setpanNumber] = useState('');
  const [selectedOption, setSelectedOption] = useState('signup'); // State to track selected option
  const [verified, setVerified] = useState(false); // State to track verified option)
  const [gst, setGst] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [otp, setOtp] = useState(['', '', '', '']);
  const [name,setPanName] = useState('');
  const otpInputs = useRef([]);

  useEffect(() => {
    // Function to store Aadhaar number in local storage
    localStorage.setItem('panNumber', panNumber);
  }, [panNumber]);

  const handleInputChange = (event) => {
    setpanNumber(event.target.value);
  };


const handlenameInputChange =(e)=>{
setPanName(e.target.value)
}


const handleDateChange = (newDate) => {
    setDateOfBirth(newDate);
  };
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      otpInputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace') {
      e.preventDefault(); // Prevent default Backspace behavior
      if (otp[index] !== '') {
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
      } else if (index > 0) {
        otpInputs.current[index - 1].focus(); // Move cursor to the leftmost empty input box
      }
    }
  };

  const onClick = () => {
     Navigate('/hospital/gstVerification')

  };
  if(gst){
    Navigate('/hospital/gstVerification')
  }
  const onClickBack = () => {

    setGst(false);
  };
  const onClickBackFromGst = () => {
    Navigate('/hospital')
  }
  const verifyPan = async () => {
    try {
     const respones = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/hospital/api/verifyPan",{
        method:'post',
        headers:{'content-type':'application/json'},
        body:JSON.stringify({
         
          "pan": panNumber,
          name:name,
          dob:dateOfBirth,
          emailID:email
         

        
        })
      })
      console.log(respones);
      if(respones.status === 200 ){
        alert("pan verified")
      setGst(true);
      }else if(respones.status === 400){
        alert("Invalid Pan number! Try Again ")
      }else{
        alert("Invalid Pan Pattern ")
      }
    } catch (error) {
      console.error('Error verifying PAN:', error);
      // Handle error appropriately (e.g., show error message to user)
    }
  };
 


  return (

    <div className={styles.main_container}>

      <div className={styles.home_container}>
        <div className={styles.background_image}>
          <img src={home_bg} alt='home_bg' />
           
        </div>
        <img src={logo} alt='logo' className={styles.logo} />
        <img src={logofitness} alt='logofitness' className={styles.logofitness} />
        <div className={styles.content}>
          <p className='text_Acron'>
            Provide accessible healthcare through MediDhan
          </p>
        </div>
      </div>



      <div className={styles.login_details}>
        <div className={styles.container}>

        


            <>
              <div className={styles.backButton_container}>
                <BackButton onClick={onClickBackFromGst} />
                <span className={styles.step}><p className='text_Cabinet'>Step 2 of 4</p></span>
              </div>

              <div className={styles.paragraph_container}>
                <p className='text_Acron'>Enter your PAN number</p>
              </div>


              <div className={styles.gstInput_container}>
                <StyledTextField

                  id="standard-basic"
                  label="Enter hospital PAN number"
                  variant="standard"
                  onChange={handleInputChange}
                  inputProps={{ inputMode: 'numeric' }}
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    input: {
                      color: 'rgba(0, 0, 0, 1)',
                      fontFamily: 'CabinetGrotesk-variable',
                      position: 'relative',
                      top: '6px',
                      left: '16px',
                      pointerEvents: 'none',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '14.82px',
                      letterSpacing: '0.08em',
                    }
                  }}
                  fullWidth
                />
              </div>

              <div className={styles.NameInput}>
                <StyledTextField
                  id="standard-basic"
                  label="Enter your name"
                  variant="standard"
                  className={styles.NameInputCls}
                  onChange={handlenameInputChange}
                  inputProps={{ inputMode: 'numeric' }}
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    label: {
                      left: '-27px',
                    },
                    input: {
                      color: 'rgba(0, 0, 0, 1)',
                      fontFamily: 'CabinetGrotesk-variable',
                      position: 'relative',
                      top: '6px',
                      left: '16px',
                      pointerEvents: 'none',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '14.82px',
                      letterSpacing: '0.08em',
                    },
                  }}
                  fullWidth
                />
              </div>

              <div className={styles.dob}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date of Birth"
                    value={dateOfBirth}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        variant="basic"
                        InputProps={{ disableUnderline: true }}
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>

              <div className={styles.sendOtp_button}>
                <Button onClick={verifyPan} >
                  Continue
                </Button>
              </div>

               



            </>
         

        </div>
      </div>

      
    </div>


  );
}

export default PanVerification;
