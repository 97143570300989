import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './IdentityVarified.module.css'; 
import varifiedAnimation from '../../common/images/varifiedAnimation.json';
import Lottie from "lottie-react";
import bg from '../../common/images/bg.png';

const IdentityVarified = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Navigate to another page after 2 seconds
    const timer = setTimeout(() => {
      navigate('/user/service'); // Replace '/another-page' with the path you want to navigate to
    }, 2000);

    // Clear the timer when the component is unmounted
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className={styles.identity_container}>
      <div className={styles.background_image}>
        <img src={bg} alt='bg' />
        <img src={bg} alt='bg' />
      </div>
      
      <div className={styles.identity_logo}>
        <Lottie className={styles.lottie} animationData={varifiedAnimation}/> 
      </div>

      <div className={styles.identity_text}>
        <p>Your identity has been verified</p>
      </div>
    </div>
  );
}

export default IdentityVarified;
