import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import styles from "./PanVarification.module.css";
import arrow_back from "../../common/images/arrow_back.png";
import { styled, TextField as MuiTextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
// If you are using date-fns v2.x, please import `AdapterDateFns`
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// If you are using date-fns v3.x, please import the v3 adapter
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { da } from 'date-fns/locale';
const StyledTextField = styled(MuiTextField)`
  width: 100%;
  // height: 50vh;
  object-fit: cover;
  &::before {
      border-bottom: none; /* Remove border just below the label */
    }
  label {
    font-family: "CabinetGrotesk-variable";
    width:222px;
    height: 16px;
    top: 6px;
    left: 16px;
    pointer-events: none;
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 16.82px;
    letter-spacing: 0.08em;
    opacity: 50%;
    &.Mui-focused {
      top: 16px;
      // Other focused label styles...
    }
    &.MuiInputLabel-shrink {
      top: 16px; // Keep the label at the top position when not focused
    }
  }
  label.Mui-focused {
    width: 222px;
    height:16px;
    top: 16px;
    left: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 14.82px;
    color: rgba(0, 0, 0, 1);
    letter-spacing: 0.08em;
    
  }

 

  .MuiInput-underline  {
    border: none;
  }
 
`;


const PanVerification = () => {
  // const classes = useStyles();
const [pan,setpan] = useState(false)
  const [panNumber, setpanNumber] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [date,setDate] = useState(null);
  const [panName,setPanName] = useState(null);

  // useEffect(() => {
  //   // Function to store pan number in local storage
  //   localStorage.setItem('panNumber', panNumber);
  // }, [panNumber]);

  const handleInputChange = (event) => {
    setpanNumber(event.target.value);
  };

  const handlenameInputChange =(e)=>{
    setPanName(e.target.value)
    }
    
    
    

    const handleDateChange = (event) => {
      const selectedDate = event.target.value;
      console.log(selectedDate)
      setDateOfBirth(selectedDate);
      
    };


    // console.log(dateOfBirth)
  const [otp, setOtp] = useState(['', '', '', '']);

  const otpInputs = useRef([]);
  const navigate = useNavigate();

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      otpInputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace') {
      e.preventDefault(); // Prevent default Backspace behavior
      if (otp[index] !== '') {
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
      } else if (index > 0) {
        otpInputs.current[index - 1].focus(); // Move cursor to the leftmost empty input box
      }
    }
  };
  console.log(localStorage)

  const onClick = async() => {
    console.log(localStorage)
    const userId =  localStorage.getItem("userId");
    // console.log(adharNumber)
   const response = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/user/api/savePanNumber",{
    method:'post',
    headers:{'Content-Type':'application/json'},
    body:JSON.stringify({pan_number:panNumber,userId:userId,panName:panName,dateOfBirth:dateOfBirth})
  }) 

  if(response.status === 200){
 

    navigate('/user/aadhaarVarification');
  }else if(response.status === 500){
    alert("Invalid Pan details")
  }else if(response.status === 400){
    alert("User not exist with registered mobile number")
  }
  else{
    alert("Something went wrong");
  }
 

  };
  const onClickBack = () => {
    setpan(false);
  };
  const onClickpan = () => {
     setpan(true);

  };

   

  return (
    <div className={styles.container}>

       


        
          <div className={styles.back_button}>
            <span
              onClick={() => navigate("/user/panVarification")}
              style={{ cursor: 'pointer', display: 'inline-block', background: 'transparent' }}
            >
              <img src={arrow_back} alt='arrow_back' style={{ width: '32px', height: '32px' }} />
            </span>
          </div>
          <div className={styles.paragraph_container}>
            <p className={styles.paragraph}>Verify your pan</p>
          </div>
          <div className={styles.panInput_container}>
            <StyledTextField

              id="standard-basic"
              label="Enter your pan number"
              variant="standard"
              onChange={handleInputChange}
              inputProps={{ inputMode: 'numeric' }}
              InputProps={{ disableUnderline: true }}
              sx={{
                input: {
                  color: 'rgba(0, 0, 0, 1)',
                  fontFamily: 'CabinetGrotesk-variable',
                  top: '20px',
                  left: '16px',
                  pointerEvents: 'none',
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '14.82px',
                  letterSpacing: '0.08em',
                }
              }}
              fullWidth
            />
             <div className={styles.NameInput}>
                <StyledTextField
                  id="standard-basic"
                  label="Enter your name"
                  variant="standard"
                  className={styles.NameInputCls}
                  onChange={handlenameInputChange}
                  inputProps={{ inputMode: 'numeric' }}
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    
                    input: {
                      color: 'rgba(0, 0, 0, 1)',
                      fontFamily: 'CabinetGrotesk-variable',
                      position: 'relative',
                      top: '20px',
                      left: '16px',
                      pointerEvents: 'none',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '14.82px',
                      letterSpacing: '0.08em',
                    },
                  }}
                  fullWidth
                />
              </div>

              <div className={styles.dob}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
              <input type="date" value={dateOfBirth} onChange={handleDateChange} />
      </LocalizationProvider>
              </div>

          </div>
          <button className={styles.continue_button} onClick={onClick}>
            <p>Verify Pan</p>
          </button>
        
      

    </div>
  );
}

export default PanVerification;
