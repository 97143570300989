import React, { useState, useEffect } from 'react';
import styles from "./LoanApproved.module.css";
import { useNavigate } from 'react-router-dom';
import rightCheck from '../images/rightCheck.png';
import { Button } from '../Buttons/Buttons';

const Service = () => {
  const navigate = useNavigate();
  const [accepted, setAccepted] = useState(false);
  const totalAmount = 500000; // Total approved credit limit
  const [emiTenure, setEmiTenure] = useState('');

  const handleSelectChange = (event) => {
    setEmiTenure(event.target.value);
  };

  const accecptance = () => {
    setAccepted(!accepted);
  };

  const onClickNext = () => {
    if (emiTenure) {
      navigate('/user/finalVerification');
    } else {
      alert("Please select EMI tenure");
    }
  };

  // Calculate monthly EMI amount based on the selected EMI tenure
  const calculateEMIAmount = () => {
    if (!emiTenure) return '';
    const emiAmount = (totalAmount / emiTenure).toFixed(2);
    return emiAmount;
  };

  // Generate upcoming dates for repayment
  const getUpcomingDates = (months) => {
    const dates = [];
    const today = new Date();
    for (let i = 1; i <= months; i++) {
      const futureDate = new Date(today);
      futureDate.setMonth(today.getMonth() + i);
      dates.push(futureDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }));
    }
    return dates;
  };

  const [repayments, setRepayments] = useState([]);

  useEffect(() => {
    if (emiTenure) {
      const upcomingDates = getUpcomingDates(Number(emiTenure));
      setRepayments(upcomingDates);
    }
  }, [emiTenure]);

  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <div className={styles.content}>
          <p className={`text_Acron ${styles.p1}`}>Congratulations! 🥳 Your approved credit limit is</p>
          <div className={styles.ConfirmedAmount}>
            <p className='text_Acron'>₹{totalAmount.toLocaleString()}</p>
          </div>
        </div>
      </div>
      <div className={styles.lowerContainer}>
        <div className={styles.interestEMIBox}>
          <div className={styles.interestEMI}>
            <p className={`text_Acron ${styles.upperText}`}>😱 0%</p>
            <p className={`text_Cabinet ${styles.lowerText}`}>Interest rate</p>
          </div>
          <div className={styles.interestEMI}>
            <div style={{display:'flex'}}> 
              <label htmlFor="emiTenureDropdown" className={`text_Acron ${styles.upperText}`}>🗓️ </label>
              <select
                id="emiTenureDropdown"
                value={emiTenure}
                onChange={handleSelectChange}
                className={`text_Acron ${styles.upperText} ${styles.selectEmi}`}
              >
                <option value="" disabled>Select tenure</option>
                <option value="3">3 months</option>
                <option value="6">6 months</option>
                <option value="9">9 months</option>
                <option value="12">12 months</option>
              </select>
            </div>
            <p className={`text_Cabinet ${styles.lowerText}`}>EMI Tenure</p>
          </div>
        </div>
        <p className={`text_Acron ${styles.repayText}`}>Repayments</p>
        <div className={styles.repayments}>
        {emiTenure ? (
            repayments.map((date, index) => (
              <div className={styles.box} key={index}>
                <div className={styles.left}>
                  <p className='text_Cabinet'>{date}</p>
                  <p className='text_Cabinet'>Upcoming</p>
                </div>
                <div className={styles.right}>
                  <p className='text_Cabinet'>₹{calculateEMIAmount()}</p>
                </div>
              </div>
            ))
          ) : (
            <p className='text_Cabinet'>Please select your EMI tenure to see the repayment schedule.</p>
          )}
        </div>
      </div>
      <div className={styles.termsConditions}>
        <div className={styles.condition}>
          <div className={`${styles.vectorContainer} ${accepted ? styles.additionalClass : ''}`} onClick={accecptance}>
            {accepted && <img src={rightCheck} alt='rightCheck' />}
          </div>
          <p className='text_Cabinet'>I accept the above loan offer with all the <span style={{ color: 'rgba(58, 171, 162, 1)', fontWeight: '700', textDecoration: 'underline' }}>T&Cs</span></p>
        </div>
        <div className={`${styles.condition} ${accepted ? styles.btn : ''}`}>
          <Button onClick={onClickNext}><p className='text_Cabinet'>Pay to merchant</p></Button>
        </div>
      </div>
    </div>
  );
};

export default Service;
