import React from 'react';
import styles from './ServiceBox.module.css'; // Ensure you import your CSS module

function ServiceBox({ serviceName, serviceImage }) {
  return (
    <div className={styles.serviceBox}>
      <div className={styles.serviceImage}>
        <img src={serviceImage} alt={serviceName} className={styles.vectorImage} />
      </div>
      <div className={styles.serviceName}>
        <p className={styles.p3}>{serviceName}</p>
      </div>
    </div>
  );
}

export default ServiceBox;
