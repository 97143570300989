import React, { useState,useEffect } from 'react';
import styles from './AccountSetup.module.css';
import logoBlue from '../../common/images/logo_blue.png';
import headerContent from '../../common/images/headerContent.png';
import arrow_forward from '../../common/images/arrow_forward.png';

import { Button } from "../Buttons/Buttons";
import uploadInvoice from "../../common/images/uploadInvoice.png";

import  {useNavigate,useLocation } from "react-router-dom";
const AccountSetup = ({videoSrc,recording}) => {
 
  const Navigate = useNavigate();
 
 
  const [hospitalName, setHospitalName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [file, setFile] = useState(null);
  // console.log(chunks)
  console.log(localStorage);
  const [photo,setPhoto] = useState(null);
 const email = localStorage.getItem("email");
 const stream = videoSrc;
 console.log(email);
 useEffect(()=>{
  setVideo();
 },[])
 const setVideo = ()=>{
  const video = document.getElementById("videoElement");
  console.log(video)
  if (video) {
    video.srcObject = videoSrc;
  }
 }
 
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setFile(file);
    console.log(file);
  
    const formData = new FormData();
    formData.append('hospitalPhoto', file);
    formData.append('email',email);
  
    try {
      const response = await fetch('https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/hospital/upload', {
        method: 'POST',
        body: formData
      });
  
      const data = await response.json();
      console.log(data)
      console.log(data.photoUrl);
      setPhoto(data.photoUrl)

    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };
  
  

  const removeFile = () => {
    setFile(null);
    setPhoto(null);
  };
  const onClickNext = async() => {
    // await mediaRecorder.stop();
    const response = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/hospital/api/saveNameandLogo",{
      method:'post',
      headers:{'Content-Type':'application/json'},
      body:JSON.stringify({name:hospitalName,logo:photo,email:email})
    })
    console.log(response);
    if(response.ok)
    Navigate('/hospital/age');
 };
 
  return (
    recording ? (<div><h1>Recording stopped can't continue. Please restart video kyc to continue</h1></div>):
    
    (
     <div className={styles.main_container}>
      <div className={styles.header_container}>
        <img src={logoBlue} alt='logoBlue' className={styles.logo_blue} />
        <img src={headerContent} alt='headerContent' className={styles.headerContent} />
      </div>

      <div className={styles.content_container}>
        
          <div className={styles.content}>
            <p className={`text_Acron ${styles.heading}`}>Help us understand your practice better</p>
            <div className={`text_Acron ${styles.text}`}>
              <span className={styles.first_text}>1 of 7 </span>
              <span>
                <img src={arrow_forward} alt='arrow_forward' className={styles.arrow_forward}></img>
              </span>
              <span className={styles.second_text}>Enter your hospital / clinic name</span>
            </div>
             {/* <video id="videoElement" autoPlay muted className={styles.video} src={videoSrc}></video> */}
            <div className={styles.input_container}>
              <input
                type="text"
                placeholder="Enter your hospital / clinic name"
                className={`text_Cabinet ${styles.input_field}`}
                value={hospitalName}
                onChange={(e) => setHospitalName(e.target.value)}
              />
              {file ? (
                <div className={styles.uploaded_logo}>
                  <div className={styles.logoDocument}>
                    <img src={`https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/${photo}`} alt='Document' />
                  </div>
                  <div className={styles.close_button} onClick={removeFile}>
                    <p>X</p>
                  </div>
                </div>
              ) : (
                <div className={styles.upload_logo}>
                  <label htmlFor="logoInput" className={`text_Cabinet ${styles.upload_label}`}>
                    <img src={uploadInvoice} alt='Upload invoice' className={styles.logo_image} />
                    <span>Upload hospital logo</span>
                    <input
                      type="file"
                      id="logoInput"
                      accept="image/*"
                      className={styles.file_input}
                      onChange={handleFileChange}
                    />
                  </label>
                </div>
              )}
            </div>
           
            <div className={styles.button_container}>
              <Button onClick={onClickNext} className={styles.next_button}>
                Next
              </Button>
            </div>
          </div>
          <div className={styles.right_content}>
        <video id="videoElement" autoPlay muted className={styles.video} src={videoSrc}></video>
        </div>
        )  
        
      </div>
    </div>
    )
  );
};

export default AccountSetup;
