import{ React,useState} from 'react';
import styles from './Home.module.css'; // Import the CSS file
import logo from '../../common/images/logo.png';
import bg from '../../common/images/bg.png';
import logofitness from '../../common/images/logofitness.png';
import EmailVerification from "../EmailVerification/EmailVerification";
import GstVerification from "../GstVerification/GstVerification";

const Home = () => {
  const [showGstVerification, setShowGstVerification] = useState(false);

  
  return (
    <div className={styles.main_container}>

      <div className={styles.home_container}>
        <div className={styles.background_image}>
          <img src={bg} alt='bg' />
          {/* <img src={bg} alt='bg' /> */}
        </div>
        <img src={logo} alt='logo' className={styles.logo} />
        <img src={logofitness} alt='logofitness' className={styles.logofitness} />
        <div className={styles.content}>
          <p className='text_Acron'>
            Provide accessible healthcare through MediDhan
          </p>
        </div>
      </div>



      <div className={styles.login_details}>
      <EmailVerification />
        
      </div>
    </div>
  );
}

export default Home;
