import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from "./UserProfile.module.css";
import edit from "../images/edit.png";
import Ellipse from "../../common/images/Ellipse.png";
import arrow_back from "../../common/images/arrow_back.png";
import coin from "../../common/images/coint.png";
import profile from "../../common/images/account_circle_FILL0_wght200_GRAD-25_opsz24.png";
import help from "../../common/images/contact_support_FILL0_wght200_GRAD-25_opsz24 2.png";
import logout from "../../common/images/Group 1216128058.png";
import { styled, TextField as MuiTextField } from '@mui/material';

const StyledTextField = styled(MuiTextField)`
  width: 100%;
  height: 50vh;
  object-fit: cover;
  &::before {
    border-bottom: none;
  }
  label {
    font-family: "CabinetGrotesk-variable";
    width: 222px;
    height: 16px;
    top: 6px;
    left: 16px;
    pointer-events: none;
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 16.82px;
    letter-spacing: 0.08em;
    opacity: 50%;
    &.Mui-focused {
      top: 16px;
    }
    &.MuiInputLabel-shrink {
      top: 16px;
    }
  }
  label.Mui-focused {
    width: 222px;
    height: 16px;
    top: 16px;
    left: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 14.82px;
    color: rgba(0, 0, 0, 1);
    letter-spacing: 0.08em;
  }
  .MuiInput-underline {
    border: none;
  }
`;

const UserProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = localStorage.getItem("userId")
  const { fullname } = location.state || {};
  const [profilePic, setProfilePic] = useState(Ellipse);

  const getUserData = async () => {
    try {
      const response = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/user/api/getUserData", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userID: userId })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const user = await response.json();
      console.log(user.User.name)

      // Extract the first name from the full name
      
const profilePhoto = user.User.profilePicUrl;
setProfilePic(profilePhoto)
      
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  


  const onClickEditProfile = () => {
    navigate('/user/editProfile');
  };

  return (
    <div className={styles.container}>
      <div className={styles.mobContainer}>
        <div className={styles.back_button}>
          <span
            onClick={() => navigate("/dashboard")}
            style={{ cursor: 'pointer', display: 'inline-block', background: 'transparent' }}
          >
            <img src={arrow_back} alt='arrow_back' style={{ width: '32px', height: '32px' }} />
          </span>
        </div>
        <div className={`${styles.d} ${styles.logoContainer}`}>
          <div className={styles.logo}>
            <img src={profilePic} alt='logoHospital2'></img>
          </div>
          {/* <div style={{
            position: "relative",
            top: "2rem",
            left: "-18px",
            background: "white",
            borderRadius: "4rem",
            width: "40px",
            height: "40px"
          }}>
            {/* <label htmlFor="profilePicInput" style={{ cursor: 'pointer' }}>
              <img src={edit} style={{
                position: "relative",
                right: "-6px",
                top: "7px",
              }} alt='user' />
            </label> */}
            {/* <input
              id="profilePicInput"
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={changeProfilePhoto}
            /> */}
          {/* </div> */}
          <span className={`text_Acron ${styles.hospitalName}`}></span>
        </div>
        <div className={styles.userName}>
          {fullname}
        </div>
        <div className={styles.loanAmount}>
          <div className={styles.amountText}>
            <span className={styles.totalLoan}>Total loan Amount.</span>
            <span className={styles.amount}>₹5,00,00 <span><img className={styles.coinImage} src={coin} alt='coin' /></span> </span>
          </div>
        </div>
        <div className={styles.userOption}>
          <div className={styles.editOption} onClick={onClickEditProfile}>
            <div className={styles.edit}>
              Edit Profile
            </div>
            <div className={styles.editImg}>
              <img src={profile} alt='edit'></img>
            </div>
          </div>
          <span className={styles.line}></span>
          <div className={styles.editOption}>
            <div className={styles.edit}>
              Help Centre
            </div>
            <div className={styles.editImg}>
              <img src={help} alt='edit'></img>
            </div>
          </div>
        </div>
        <div className={styles.logOut}>
          <img src={logout} alt='logout'></img>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
