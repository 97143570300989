import React, { useState, useEffect } from 'react';
import styles from "./DocumentsVerification.module.css"
import { useNavigate } from 'react-router-dom';
import logoDone from '../images/logoDone.png';
import rupeesImage from '../images/rupeesImage.png';

const Service = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [completed, setCompleted] = useState({
    identity: false,
    income: false,
    bank: false,
    Score: false
  });
  const [selected, setSelected] = useState("identity");

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      setCompleted(prevCompleted => ({ ...prevCompleted, [selected]: true }));
    }, 3000);

    return () => clearTimeout(timer);
  }, [selected]);

  useEffect(() => {
    const nextStep = {
      identity: 'income',
      income: 'bank',
      bank: 'Score',
      Score: null // No next step after Score
    }[selected];

    if (completed[selected] && nextStep) {
      setSelected(nextStep);
    } else if (completed[selected] && !nextStep) {
      // Navigate to loanApproved if all steps are completed
      navigate('/user/loanApproved');
    }
  }, [completed, navigate, selected]);

  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <div className={styles.content}>
          <p className={`text_Acron ${styles.p1}`}>We are verifying your documents</p>
          <p className={`text_Cabinet ${styles.p2}`}>Please wait for</p>
          <p className={`text_Acron ${styles.time}`}>5 min : 00 sec</p>
          <div className={styles.RequestedAmount}>
            <p className='text_Cabinet'>Request for ₹5,00,000</p>
            <img src={rupeesImage} alt='rupeesImage' />
          </div>
        </div>
      </div>
      <p className={`text_Cabinet ${styles.p3}`}>Your loan is in</p>
      <div className={styles.VerifyingContainer}>
        <div className={`${styles.box} ${completed.identity === true || selected === 'identity' ? styles.selected : ''}`}>
          {completed.identity ? (
            <div className={styles.confirmationSign}>
              <img src={logoDone} alt='logoDone' />
            </div>
          ) : (selected === 'identity' &&
            <div className={`${styles.spin} ${loading ? styles.loading : ''} `}></div>
          )}
          <span>
            <p className={`text_Acron`}>Verifying identity proof</p>
          </span>
        </div>
        <div className={`${styles.box} ${completed.income === true || selected === 'income' ? styles.selected : ''}`}>
          {completed.income ? (
            <div className={styles.confirmationSign}>
              <img src={logoDone} alt='logoDone' />
            </div>
          ) : (selected === 'income' &&
            <div className={`${styles.spin} ${loading ? styles.loading : ''} `}></div>
          )}
          <span>
            <p className={`text_Acron`}>Verifying income proof</p>
          </span>
        </div>
        <div className={`${styles.box} ${completed.bank === true || selected === 'bank' ? styles.selected : ''}`}>
          {completed.bank ? (
            <div className={styles.confirmationSign}>
              <img src={logoDone} alt='logoDone' />
            </div>
          ) : (selected === 'bank' &&
            <div className={`${styles.spin} ${loading ? styles.loading : ''} `}></div>
          )}
          <span>
            <p className={`text_Acron`}>Verifying bank statements</p>
          </span>
        </div>
        <div className={`${styles.box} ${completed.Score === true || selected === 'Score' ? styles.selected : ''}`}>
          {completed.Score ? (
            <div className={styles.confirmationSign}>
              <img src={logoDone} alt='logoDone' />
            </div>
          ) : (selected === 'Score' &&
            <div className={`${styles.spin} ${loading ? styles.loading : ''} `}></div>
          )}
          <span>
            <p className={`text_Acron`}>Verifying Bureau Score</p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Service;
