import React, { useState,useEffect } from 'react';
import styles from './Service.module.css';
import logoBlue from '../../common/images/logo_blue.png';
import headerContent from '../../common/images/headerContent.png';
import arrow_forward from '../../common/images/arrow_forward.png';

import { BackButton, Button } from "../Buttons/Buttons";

import Orthopaedic from "../../common/images/orthopaedicReplacement.png"
import generalSurgery from "../../common/images/generalSurgery .png"
import ivf from "../../common/images/ivf.png";
import rootCanal from "../../common/images/rootCanal.png";
import dentalRestoration from "../../common/images/dentalRestoration.png"
import fertility from "../../common/images/fertility.png"
import hairTransplant from "../../common/images/hairTransplant.png"
import cryotherapy from "../../common/images/cryotherapy.png"
import { useNavigate } from "react-router-dom";




const AccountSetup = ({videoSrc,recording}) => {
  const email = localStorage.getItem('email');
  const Navigate = useNavigate();
  const stream = videoSrc;
  console.log(email);
  useEffect(()=>{
    setVideo();
   },[])
   const setVideo = ()=>{
    const video = document.getElementById("videoElement");
    console.log(video)
    if (video) {
      video.srcObject = videoSrc;
    }
   }
  const [services, setServices] = useState([
    // { id: 1, name: "Orthopaedic surgery", logo: "" },
    { id: 2, name: "Orthopaedic replacement", logo: Orthopaedic },
    { id: 3, name: "General surgery ", logo: generalSurgery },
    { id: 4, name: "IVF", logo: ivf },
    { id: 5, name: "Root canal", logo: rootCanal },
    { id: 6, name: "Dental restoration", logo: dentalRestoration },
   
    { id: 7, name: "Fertility ", logo: fertility },
    { id: 9, name: "Hair transplant", logo: hairTransplant },
   
    { id: 10, name: "Cryotherapy", logo: cryotherapy },
    
    
    

  


  ]);

  const [boxClicked, setBoxClicked] = useState(false);
  const [clickedService, setClickedService] = useState(null);

  const [selectedServices, setSelectedServices] = useState(new Set());

  const handleServiceClick = (id) => {
    const updatedSelectedServices = new Set(selectedServices);
    if (updatedSelectedServices.has(id)) {
      updatedSelectedServices.delete(id);
    } else {
      updatedSelectedServices.add(id);
    }
    setSelectedServices(updatedSelectedServices);
    setBoxClicked(true);
  };
  
  const onClick = async() => {
    console.log(selectedServices);
    let serviceData = [];
  
    services.forEach(service => {
      if (selectedServices.has(service.id)) {
        serviceData.push(service);
      }
    });
  
    // Now, serviceData contains the data of selected services
    console.log(serviceData);
    // Do something with serviceData, such as passing it to another component or processing it further
    const response  = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/hospital/api/saveServices",{
      method:'post',
      headers:{'Content-Type':'application/json'},
      body:JSON.stringify({services:serviceData,email:email})
    });
    
    // Assuming you're using React Router for navigation
    if(response.ok)
    Navigate("/hospital/patients");
  else{
    alert("error in service saving");
  }
  }
  

  const onClickBack = () => {
    Navigate('/hospital/hospital')
  }

  return (
    !recording ? (<div><h1>Recording stopped can't continue. Please restart video kyc to continue</h1></div>):
    (
    <div className={styles.main_container}>
      <div className={styles.header_container}>
        <img src={logoBlue} alt='logoBlue' className={styles.logo_blue} />
        <img src={headerContent} alt='headerContent' className={styles.headerContent} />
      </div>

      <div className={styles.content_container}>
        <div className={styles.content}>
          <div className={styles.heading}>
            <BackButton onClick={onClickBack}></BackButton>
          </div>
          <div className={`text_Acron ${styles.text}`}>
            <span className={styles.first_text}>4 of 6 </span>
            <span>
              <img src={arrow_forward} alt='arrow_forward' className={styles.arrow_forward}></img>
            </span>
            <span className={styles.second_text}>Select treatments you are providing</span>
          </div>
          <div className={styles.service_container}>
            <div className={styles.services}>
              {services.map((service) => (
                <div
                  key={service.id}
                  className={`${styles.serviceBox} ${
                    selectedServices.has(service.id) ? styles.clicked : ''
                  }`}
                  onClick={() => handleServiceClick(service.id)}
                >
                  <div className={styles.serviceImage}>
                    <img src={service.logo} alt="Arthopaedic" className={styles.vectorImage} />
                  </div>
                  <div className={styles.serviceName}>
                    <p className={`${styles.p3}`}>{service.name}</p>
                  </div>
                </div>
              ))}
            </div>
            {selectedServices.size > 0 && (
              <div className={styles.btn}>
                <Button className={styles.continue_buttons} onClick={onClick}>
                  Continue
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.right_content}>
        <video id="videoElement" autoPlay muted className={styles.video} src={videoSrc}></video>
        </div>
    </div>
    )
  );
};

export default AccountSetup;