import React, { useState, useEffect } from 'react';
import styles from './Header.module.css';
import logo from '../images/logo_blue.png';


function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-DJ7XNB2XPG";
    script1.async = true;
    document.head.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-DJ7XNB2XPG');
    `;
    document.head.appendChild(script2);

    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.header}>
        <img src={logo} alt='logo' />
        <div className={styles.menuIcon} onClick={toggleMobileMenu}>
          ☰
        </div>
        <ul className={`${styles.navLinks} ${isMobileMenuOpen ? styles.showMenu : styles.hideMenu}`}>
          <li><a href="/" className='text_Cabinet' rel="noopener noreferrer" onClick={closeMobileMenu}>Home</a></li>
          <li><a href="/user/" className='text_Cabinet' rel="noopener noreferrer" onClick={closeMobileMenu}>For Patients</a></li>
          <li><a href="/hospital/" className='text_Cabinet' target="_blank" rel="noopener noreferrer" onClick={closeMobileMenu}>For Partners</a></li>
          <li><a href="/aboutUs" className='text_Cabinet' rel="noopener noreferrer" onClick={closeMobileMenu}>About</a></li>
        </ul>
      </div>

    </div>
  );
}

export default Header;
