import React, { useState } from 'react';
import styles from './Vkyc.module.css';
import social_media from '../../common/images/social media.png';
import logoButton from '../../common/images/logoButton.png';
import { Button } from "../Buttons/Buttons";
import { useNavigate } from 'react-router-dom';

const Vkyc = ({videoSrc,setVideoSrc,startRecording,stopRecording,permission}) => {
  const [recordingStarted, setRecordingStarted] = useState(false);
  // const [isRecording,setIsRecording]  = useState(false)
  // const [chunks,setChunks] = useState([]);

  const Navigate = useNavigate();
 

  const onClickStartRecording = () => {
    startRecording();
    setRecordingStarted(true);
  };

  const onClickNextStep = () => {
// mediaRecorder.pause();
// stopRecording();
if(recordingStarted && permission)
    Navigate('/hospital/accountSetup');
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.left_content}>
        <p className={`text_Acron ${styles.p1}`}>Complete your VKYC</p>
        <p className={`text_Cabinet ${styles.p2}`}>What do you need to record?</p>
        <div className={styles.content_type}>
          <div className={styles.div1}>
            <p className={`text_Acron ${styles.text1}`}>1</p>
            <p className={`text_Cabinet ${styles.text2}`}>Your name</p>
          </div>
          <div className={styles.div1}>
            <p className={`text_Acron ${styles.text1}`}>2</p>
            <p className={`text_Cabinet ${styles.text2}`}>Name of your hospital/clinic</p>
          </div>
          <div className={styles.div1}>
            <p className={`text_Acron ${styles.text1}`}>3</p>
            <p className={`text_Cabinet ${styles.text2}`}>Years of practice</p>
          </div>
        </div>
        <div className={styles.btn}>
          {recordingStarted ? (
            <Button onClick={onClickNextStep}>
              <div className={styles.btn_content}>
                <img src={logoButton} alt='logoButton'></img>
                <p className='text_Cabinet'>Next Step</p>
              </div>
            </Button>
          ) : (
            <Button onClick={onClickStartRecording}>
              <div className={styles.btn_content}>
                <img src={logoButton} alt='logoButton'></img>
                <p className='text_Cabinet'>Start Recording</p>
              </div>
            </Button>
          )}
        </div>
      </div>
      <div className={styles.right_content}>
       <video id="videoElement"  autoPlay muted className={styles.video}></video>
       
        <img src={social_media} alt='social_media' />
      </div>
    </div>
  );
};

export default Vkyc;
