import React, { useState,useEffect } from 'react';
import styles from './Patients.module.css';
import logoBlue from '../../common/images/logo_blue.png';
import headerContent from '../../common/images/headerContent.png';
import arrow_forward from '../../common/images/arrow_forward.png';
import { Button,BackButton } from "../Buttons/Buttons";
import { useNavigate } from 'react-router-dom';


const AccountSetup = ({videoSrc,recording}) => {
  const Navigate = useNavigate();
  const email = localStorage.getItem('email');
  const [patients, setPatients] = useState('');
  useEffect(()=>{
    setVideo();
   },[])
   const setVideo = ()=>{
    const video = document.getElementById("videoElement");
    console.log(video)
    if (video) {
      video.srcObject = videoSrc;
    }
   }
  const onClickNextPatients = async() => {
    

    const response = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/hospital/api/savePateintDetail",{
      method:'post',
      headers:{'content-type':'application/json'},
      body:JSON.stringify({"patient":patients,'email':email})
    })
    if(response.ok)
       Navigate('/hospital/bankDetails');
  };
  const onClickBack = () => {
    Navigate('/hospital/service');
  }

  return (
    !recording ? (<div><h1>Recording stopped can't continue. Please restart video kyc to continue</h1></div>):
    (
    <div className={styles.main_container}>
      <div className={styles.header_container}>
        <img src={logoBlue} alt='logoBlue' className={styles.logo_blue} />
        <img src={headerContent} alt='headerContent' className={styles.headerContent} />
      </div>

      <div className={styles.content_container}>
       
          <div className={styles.content}>
          <div className={styles.heading}>
            <BackButton onClick={onClickBack}></BackButton>
          </div>
            <div className={`text_Acron ${styles.text}`}>
              <span className={styles.first_text}>5 of 6 </span>
              <span>
                <img src={arrow_forward} alt='arrow_forward' className={styles.arrow_forward}></img>
              </span>
              <span className={styles.second_text}> how many patients you can attend in a day?</span>
            </div>
            <div className= {styles.input_container} >
              <input
                maxLength={4}
                type="numeric"
                placeholder="| 0" 
                 
                className={`text_Acron ${styles.input_field} `}
                value={patients}
                onChange={(e) => setPatients(e.target.value)}
              />
              <span className={`text_Cabinet ${styles.years}`}>patients</span>
            </div>
            <div className={styles.button_container}>
              <Button onClick={onClickNextPatients} className={styles.next_button}>
                Next
              </Button>
            </div>
          </div>
         
      </div>
      <div className={styles.right_content}>
        <video id="videoElement" autoPlay muted className={styles.video} src={videoSrc}></video>
        </div>
    
    </div>
    )
  );
};

export default AccountSetup;
