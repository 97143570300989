import React, { useState } from 'react';
import { json, useNavigate } from 'react-router-dom';

import styles from './CibilScore.module.css';
import { Button } from '../Buttons/Buttons';
import ProgressBar from '../ProgressBar/ProgressBar';


const CibilScore = () => {

  const Navigate = useNavigate();
 const [score, setScore] = useState(780);
 const userId = localStorage.getItem("userId");

  const onClick = async () => {
    const response = await fetch("http://localhost:5000/user/api/savecibilscore",{
      method:'post',
      headers:{'Content-Type':'application/json'},
      body:JSON.stringify({cibil:score,userId:userId})
    })
    console.log(response)
    if(response.ok){

    Navigate('/user/personalDetail')
    }
  }


  return (
    <div className={styles.identity_container}>
      <div className={styles.logoContainer}>
        <p className='text_Acron'> Yaayyy! Your cibil score looks great 🤩</p>
      </div>

      <div className={styles.score}>
      <ProgressBar percentage={180-(0.2*780       )} ></ProgressBar>
      <div className={styles.points}>
        <p className='text_Acron'> {score}</p>
      </div>
      </div>

      <div className={styles.identity_text}>

        <p className={`text_Cabinet`}>Minimum cibil score required is 700</p>
      </div>



      <div className={styles.btn}>
        <Button onClick={onClick} >
          <p>Proceed to eNACH</p>
        </Button>

      </div>
    </div>



  );
}

export default CibilScore;