// src/hooks/usePermissions.js
import { useState } from 'react';

const usePermissions = () => {
  const [permissionsGranted, setPermissionsGranted] = useState({
    location: false,
    contacts: false,
    sms: false,
  });

  const requestLocationPermission = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setPermissionsGranted((prev) => ({ ...prev, location: true }));
            resolve(position);
          },
          (error) => {
            setPermissionsGranted((prev) => ({ ...prev, location: false }));
            reject(error);
          }
        );
      } else {
        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  };

  const requestContactsPermission = () => {
    return new Promise((resolve) => {
      // Simulate contacts permission request
      setTimeout(() => {
        setPermissionsGranted((prev) => ({ ...prev, contacts: true }));
        resolve(true);
      }, 500);
    });
  };

  const requestSmsPermission = () => {
    return new Promise((resolve) => {
      // Simulate SMS permission request
      setTimeout(() => {
        setPermissionsGranted((prev) => ({ ...prev, sms: true }));
        resolve(true);
      }, 500);
    });
  };

  const requestPermissions = async () => {
    try {
      await requestLocationPermission();
      
      await requestContactsPermission();
     
      await requestSmsPermission();
     
     
      return true;
    } catch (error) {
      console.error('Permission request failed:', error);
      return false;
    }
  };
  // console.log(permissionsGranted.location)
  // console.log(permissionsGranted.contacts)
  // console.log(permissionsGranted.sms)
  return {
    permissionsGranted,
    requestPermissions,
  };
};

export default usePermissions;
