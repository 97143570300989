/* eslint-disable no-shadow */
import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell } from 'recharts';

const RADIAN = Math.PI / 180;
const data = [{ name: 'A', value: 50, color: 'rgba(255, 212, 112, 1)' }];
const cx = 98;
const cy = 96;
const iR = 65;
const oR = 100;

const needle = (value, data, cx, cy, iR, oR, color) => {
  const yellowValue = value;
  const grayValue = 100 - value;

  return [
    { value: yellowValue, color: color },
    { value: grayValue, color: 'rgba(100, 100, 100, 0.2)' }
  ];
};

export default class Example extends PureComponent {
  render() {
    const { percentage } = this.props;
    const backgroundColor = 'rgba(100, 100, 100, 0.2)'; // Background color
    const borderColor = 'rgba(247, 245, 243, 1)'; // Border color
    const needleData = needle(percentage, data, cx, cy, iR, oR, data[0].color);

    return (
      <PieChart width={200} height={200}>
        {/* Background */}
        <Pie
          data={needleData}
          startAngle={180}
          endAngle={0}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          fill={backgroundColor} // Set the background color
          stroke={borderColor} // Set the border color
          strokeWidth={2} // Adjust the border width as needed
        />
        {/* Needle */}
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={percentage}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          fill="#8884d8"
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    );
  }
}
