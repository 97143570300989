import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Googletranslator.module.css';
import logo from '../../common/images/logo.png';
import bg from '../../common/images/bg.png';
import { Navigate } from 'react-router-dom';



import { useNavigate } from 'react-router-dom';

const GoogleTranslate = () => {
  const navigate = useNavigate();
  const [languageSelected, setLanguageSelected] = useState(false);
  // const navigate = useNavigate();
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
  //   script.async = true;
  //   document.body.appendChild(script);

  //   window.googleTranslateElementInit = () => {
  //     const translator = new window.google.translate.TranslateElement(
  //       { pageLanguage: 'en' },
  //       'google_translate_element'
  //     );

  //     const goog = document.getElementsByClassName('goog-te-combo');
  //     if (goog.length > 0) {
  //       goog[0].addEventListener('change', function (event) {
  //         const selectedValue = event.target.value;
  //         setLanguageSelected(true);
  //         // onLanguageSelect(selectedValue);
  //       });
  //     }
  //   };

  //   }, []);
  // if(languageSelected){
  //   navigate("/login");
  // }
  const onClick = ()=>{
   setLanguageSelected(true)
  }

  return (
    <div className={styles.home_container}>
      <div className={styles.background_image}>
        <img src={bg} alt='bg' />
        <img src={bg} alt='bg' />
      </div>
      <div className={styles.home_content}>
        <img src={logo} alt='logo' />
        <p>Empowering healthcare finances</p>
      </div>
      <div className={styles.googltranslatepageconatiner}>
        <div className={styles.languagebox}>
          <div className={`text_Cabinet ${styles.langText}`}>Select your language to continue</div>
          <div id="google_translate_element" style={{ position: 'absolute', zIndex: '2' }}></div>
        </div>
        
      </div>
    </div>
  );
};

// GoogleTranslate.propTypes = {
//   onLanguageSelect: PropTypes.func.isRequired,
 
// };

export default GoogleTranslate;
