import React, { useState, useEffect, useRef } from 'react';
import { useNavigate,useLocation } from 'react-router';
import styles from "./GstVerification.module.css";
import arrow_back from "../../common/images/arrow_back.png";
import line from "../../common/images/line.png";
import logo from '../../common/images/logo.png';
import home_bg from '../../common/images/home_bg.png';
import logofitness from '../../common/images/logofitness.png';
import logoGoogle from "../../common/images/logoGoogle.png"
import { styled, TextField as MuiTextField } from '@mui/material';
import { BackButton, Button } from '../Buttons/Buttons';
import logoEdit from "../../common/images/logoEdit.png";
import IdentityVarified from '../Identity/IdentityVarified';
import crossBackWhite from "../../common/images/crossBackWhite.png";
import zIndex from '@mui/material/styles/zIndex';

const StyledTextField = styled(MuiTextField)`
  width: 100%;
  height: 50vh;
  object-fit: cover;
  &::before {
      border-bottom: none; /* Remove border just below the label */
    }
  label {
    font-family: "CabinetGrotesk-variable";
    position: relative;
    width:222px;
    height: 16px;
    top: 6px;
    left: 16px;
    pointer-events: none;
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 16.82px;
    letter-spacing: 0.08em;
    opacity: 50%;
    &.Mui-focused {
      top: 16px;
      // Other focused label styles...
    }
    &.MuiInputLabel-shrink {
      top: 16px; // Keep the label at the top position when not focused
    }
  }
  label.Mui-focused {
    position: relative;
    width: 222px;
    height:16px;
    top: 19px;
    left: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 14.82px;
    color: rgba(0, 0, 0, 1);
    letter-spacing: 0.08em;
    
  }

 

  .MuiInput-underline  {
    border: none;
  }
 
`;


const GstVerification = () => {
  // const classes = useStyles();
  const Navigate = useNavigate();

const email = localStorage.getItem("email");


 console.log(email)
  const [gstNumber, setGstNumber] = useState('');
  const [selectedOption, setSelectedOption] = useState('signup'); // State to track selected option
  const [verified, setVerified] = useState(false); // State to track verified option)
  const [gst, setGst] = useState(false);
  useEffect(() => {
    // Function to store Aadhaar number in local storage
    localStorage.setItem('gstNumber', gstNumber);
  }, [gstNumber]);

  const handleInputChange = (event) => {
    setGstNumber(event.target.value);
  };



  const [otp, setOtp] = useState(['', '', '', '']);

  const otpInputs = useRef([]);

  const onClickBackCross = () => {
   
    Navigate('/vkyc')
  }
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      otpInputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace') {
      e.preventDefault(); // Prevent default Backspace behavior
      if (otp[index] !== '') {
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
      } else if (index > 0) {
        otpInputs.current[index - 1].focus(); // Move cursor to the leftmost empty input box
      }
    }
  };

  // const onClick = async (e) => {

  //   e.preventDefault();
  //   try {
  //     const response = await fetch("http://localhost:3000/api/saveGst", {
  //       method: 'post',
  //       headers: {
  //         "Content-Type": "application/json"
  //       },
  //       body: JSON.stringify({
  //         gst_number: gstNumber,
  //         emailId: email,
  //       })
  //     });
  
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }
  
  //     const responseData = await response.json(); // Convert response body to JSON
  //     console.log(responseData);
  
  //     alert('Your GST Number has been added successfully');
  //     setVerified(!verified);
  //   } catch (error) {
  //     console.error("Error adding GST Number:", error.message);
  //     alert('Failed to add GST Number');
  //   }
  // };
  
  const onClickBack = () => {

    setGst(false);
  };
  const onClickBackFromGst = () => {
    Navigate('/hospital')
  }
  const onClickGst = async(e) => {


    e.preventDefault();
    try {
      const response = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/hospital/api/saveGst", {
        method: 'post',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          gst_number: gstNumber,
          emailId: email,
        })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
   if(response.status === 200){
  
      alert('Your GST Number has been added successfully');
      setVerified(!verified);
   }else if(response.status === 400) {
    alert('Invalid GSTIN Number');
   }
    } catch (error) {
      console.error("Error adding GST Number:", error.message);
      alert('Failed to add GST Number');
    }

  };

if(verified){
  Navigate('/hospital/vkyc')
}

  return (

    <div className={styles.main_container}>

      <div className={styles.home_container}>
        <div className={styles.background_image}>
          <img src={home_bg} alt='home_bg' />
           
        </div>
        <img src={logo} alt='logo' className={styles.logo} />
        <img src={logofitness} alt='logofitness' className={styles.logofitness} />
        <div className={styles.content}>
          <p className='text_Acron'>
            Provide accessible healthcare through MediDhan
          </p>
        </div>
      </div>



      <div className={styles.login_details}>
        <div className={styles.container}>

           


            <>
              <div className={styles.backButton_container}>
                <BackButton onClick={onClickBackFromGst} />
                <span className={styles.step}><p className='text_Cabinet'>Step 2 of 3</p></span>
              </div>

              <div className={styles.paragraph_container}>
                <p className='text_Acron'>Enter your GST number</p>
              </div>


              <div className={styles.gstInput_container}>
                <StyledTextField

                  id="standard-basic"
                  label="Enter hospital GST number"
                  variant="standard"
                  onChange={handleInputChange}
                  inputProps={{ inputMode: 'numeric' }}
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    input: {
                      color: 'rgba(0, 0, 0, 1)',
                      fontFamily: 'CabinetGrotesk-variable',
                      position: 'relative',
                      top: '6px',
                      left: '16px',
                      pointerEvents: 'none',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '14.82px',
                      letterSpacing: '0.08em',
                    }
                  }}
                  fullWidth
                />
              </div>

              <div className={styles.sendOtp_button}>
                <Button onClick={onClickGst} >
                  Continue
                </Button>
              </div>

             


            </>
         
        </div>
      </div>

      {verified && (
      <div className={styles.overlay}>
      <div className={styles.identity}>
      <div className={styles.crossBack} onClick={onClickBackCross} style={{position:'relative', right:'-19rem', top:'5rem',cursor:'pointer',zIndex:'4'}}>
              <img src={crossBackWhite}  alt='crossBackWhite'  />
                             
            </div>
      <IdentityVarified text = "Your identity has been verified"/>
      </div>
        {/* You can style this overlay div with a background color and blur effect */}
        <div className={styles.blur_effect}></div>
      </div>
    )}
    </div>


  );
}

export default GstVerification;
