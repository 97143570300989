
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from "./Amount.module.css";
import arrow_back from "../images/arrow_back.png";
import { Navigate, useLocation } from 'react-router-dom';
 
 


const Amount = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const serviceName = location.state?.service_name || '';


  const [amount, setAmount] = useState('');

//   useEffect(() => {
//     // Function to store amount number in local storage
//     localStorage.setItem('amount', amount);
//   }, [amount]);

  const handleInputChange = (event) => {
    setAmount(event.target.value);
  };

   const onClick = async() => {
    const userId = localStorage.getItem("userId");
   const response = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/user/api/saveServiceAmount",{
    method:'post',
    headers:{'Content-Type':'application/json'},
    body:JSON.stringify({"service_name":serviceName,"amount":amount,"userId":userId})
   });
   if(response.ok){
    navigate('/user/cibilScore');
    // alert("service amount added")
   }else{
    alert("error in adding amount")
   }
  };




  return (
    <div className={styles.container}>


      <div className={styles.back_button}>
        <span
          onClick={() => navigate("/service")}
          style={{ cursor: 'pointer', display: 'inline-block', background: 'transparent' }}
        >
          <img src={arrow_back} alt='arrow_back' style={{ width: '32px', height: '32px' }} />
        </span>
      </div>
      <div className={styles.paragraph_container}>
        <div className={styles.paragraph}>How much amount do you need for
          <span className={styles.paragraph}>IVF</span>
        </div>

      </div>
      <div className={styles.amountInput_container}>
      <p className='text_Acron'>₹</p>
        <input className='text_Acron'
        
            type="numeric"
            placeholder='0'
            onChange={handleInputChange}
        />
      </div>
     
      <button className={styles.continue_button} onClick={onClick}>
      <p>Continue</p>
    </button>
      

    </div>
  );
}

export default Amount;