// FileUpload.js

import React from 'react';
import styles from "./FileUpload.module.css";
import uploadInvoice from "../images/uploadInvoice.png";
import logoDocument from "../images/logoDocument.png";
import crossClose from '../images/crossClose.png';

const FileUpload = ({ labelFor, onChange, file, onRemove }) => {
  return (
    <>
      {file ? (
        <div className={styles.uploaded_file}>
          <div className={styles.logoDocument}>
            <img src={logoDocument} alt='Document' />
          </div>
          <div className={styles.close_button} onClick={onRemove}>
             <img src={crossClose} alt='crossClose'></img>
          </div>
        </div>
      ) : (
        <div className={styles.upload_container}>
          <label htmlFor={labelFor} className={styles.upload_label}>
            <img src={uploadInvoice} alt='Upload invoice' />
            <span className='text_Cabinet'>Upload </span>
            <input
              type="file"
              id={labelFor}
              className={styles.file_input}
              onChange={onChange}
            />
          </label>
        </div>
      )}
    </>
  );
};

export default FileUpload;
