// Layout.js

import React from 'react';
import styles from './Layout.module.css'; // Import the CSS file
import { Outlet } from 'react-router-dom';

const Layout = ({ children }) => {
  return (
    
      <main className={styles.layout}>
        <div className={styles.page_container}> <Outlet/></div>
       
      </main>
     
  );
}

export default Layout;
