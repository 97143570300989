// Layout.js

import React from 'react';
import styles from './Layout.module.css'; // Import the CSS file
import { Outlet } from 'react-router-dom';

const Layout = ({ children }) => {
  return (
    <div className={styles.layout_container}>
      <div className={styles.layout}>
         
        <main>
          <div className={styles.page_container}><Outlet/></div>
        </main>
         
      </div>
    </div>
  );
}

export default Layout;
