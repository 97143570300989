import React, { useState, useEffect, useRef } from 'react';
import { redirect, useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import styles from "./EditUser.module.css";
import edit from "../images/edit.png";
import Ellipse from "../images/Ellipse.png";
import arrow_back from "../images/arrow_back.png";
import { styled, TextField as MuiTextField } from '@mui/material';

const StyledTextField = styled(MuiTextField)`
  width: 100%;
  height: 50vh;
  object-fit: cover;
  &::before {
      border-bottom: none; /* Remove border just below the label */
    }
  label {
    font-family: "CabinetGrotesk-variable";
    width:222px;
    height: 16px;
    top: 6px;
    left: 16px;
    pointer-events: none;
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 16.82px;
    letter-spacing: 0.08em;
    opacity: 50%;
    &.Mui-focused {
      top: 16px;
      // Other focused label styles...
    }
    &.MuiInputLabel-shrink {
      top: 16px; // Keep the label at the top position when not focused
    }
  }
  label.Mui-focused {
    width: 222px;
    height:16px;
    top: 16px;
    left: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 14.82px;
    color: rgba(0, 0, 0, 1);
    letter-spacing: 0.08em;
  }

  .MuiInput-underline {
    border: none;
  }
`;

const EditUser = () => {
  const [name, setName] = useState('');
  const [email,setEmail] = useState('');
  const [pan, setpan] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const userId = localStorage.getItem("userId")
  const [address, setAddress] = useState(false);
  const [userAdress, setUserAdress] = useState('');
  const [profilePic, setProfilePic] = useState(Ellipse);

  const handleNameInputChange = (event) => {
    setName(event.target.value);
  };
  const handleEmailInputChange = (event)=>{
    setEmail(event.target.value);
  }

  const [otp, setOtp] = useState(['', '', '', '']);
  const otpInputs = useRef([]);
  const navigate = useNavigate();

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      otpInputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      if (otp[index] !== '') {
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
      } else if (index > 0) {
        otpInputs.current[index - 1].focus();
      }
    }
  };
  
  const changeProfilePhoto = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('profilePic', file);
      formData.append('userId', userId); // Assuming you pass userId in the state

      try {
        const response = await fetch('https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/user/api/uploadProfilePic', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setProfilePic(data.profilePicUrl); 
        // Update state with new profile picture URL
        console.log(profilePic)
      } catch (error) {
        console.error('Error uploading profile picture:', error);
      }
    }
  };
  const getUserData = async () => {
    try {
      const response = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/user/api/getUserData", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userID: userId })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const user = await response.json();
      console.log(user.User.name)

      // Extract the first name from the full name
      
const profilePhoto = user.User.profilePicUrl;
setProfilePic(profilePhoto)
      
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setUserAdress(value);
    
    const response = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/user/places", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ query: value })
    });

    const jsondata = await response.json();
    console.log(jsondata);
    setSuggestions(jsondata.predictions);
  };

  const onSelectAddress = (selectedAddress) => {
    setAddress(selectedAddress);
    setUserAdress(selectedAddress.description);
    setSuggestions([]);
  };

  const onClick = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        alert("User ID is missing.");
        return;
      }
  
      const response = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/user/api/update/UserInformation", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: name, email: email, userAdress: userAdress, userId: userId })
      });
  
      if (response.ok) {
        const data = await response.json();
        alert("User info updated successfully.");
        // Assuming `redirect` is a function that navigates to a different route
      navigate("/user/dashboard")
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.error}`);
      }
    } catch (error) {
      console.error("Error updating user information:", error);
      alert("An unexpected error occurred. Please try again later.");
    }
  };
  

  const onClickBack = () => {
    setpan(false);
  };

  const onClickpan = () => {
    setpan(true);
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.d} ${styles.logoContainer}`}>
        <div className={styles.logo}>
          <img src={profilePic} alt='logoHospital2'></img>
        </div>
           <div style={{
            position: "relative",
            top: "2rem",
            left: "-18px",
            background: "white",
            borderRadius: "4rem",
            width: "40px",
            height: "40px"
          }}>
             <label htmlFor="profilePicInput" style={{ cursor: 'pointer' }}>
              <img src={edit} style={{
                position: "relative",
                right: "-6px",
                top: "7px",
              }} alt='user' />
            </label> 
             <input
              id="profilePicInput"
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={changeProfilePhoto}
            /> 
           </div> 
        <span className={`text_Acron ${styles.hospitalName}`}>{}</span>
      </div>
      <div className={styles.back_button}>
        <span
          onClick={() => navigate("/user/panVarification")}
          style={{ cursor: 'pointer', display: 'inline-block', background: 'transparent' }}
        >
          <img src={arrow_back} alt='arrow_back' style={{ width: '32px', height: '32px' }} />
        </span>
      </div>
      <div className={styles.panInput_container}>
        <StyledTextField
          id="standard-basic"
          label="Your name"
          variant="standard"
          onChange={handleNameInputChange}
          inputProps={{ inputMode: 'text' }}
          InputProps={{ disableUnderline: true }}
          sx={{
            input: {
              color: 'rgba(0, 0, 0, 1)',
              fontFamily: 'CabinetGrotesk-variable',
              pointerEvents: 'none',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '14.82px',
              letterSpacing: '0.08em',
             
            }
          }}
          fullWidth
        />
      </div>
      <div className={styles.panInput_container} style={{ marginTop: "8px" }}>
        <StyledTextField
          id="standard-basic"
          label="Enter Your Email id"
          variant="standard"
          onChange={handleEmailInputChange}
          inputProps={{ inputMode: 'text' }}
          InputProps={{ disableUnderline: true }}
          sx={{
            input: {
              color: 'rgba(0, 0, 0, 1)',
              fontFamily: 'CabinetGrotesk-variable',
              pointerEvents: 'none',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '14.82px',
              letterSpacing: '0.08em',
            }
          }}
          fullWidth
        />
      </div>
      <div className={styles.panInput_container} style={{ marginTop: "8px" }}>
        <StyledTextField
          id="standard-basic"
          label="Select your current location"
          variant="standard"
          value={userAdress}
          inputProps={{ inputMode: 'numeric' }}
          InputProps={{ disableUnderline: true }}
          onChange={(e) => handleSearchChange(e)}
          onClick={() => setAddress(true)}
          sx={{
            input: {
              color: 'rgba(0, 0, 0, 1)',
              fontFamily: 'CabinetGrotesk-variable',
              top: '20px',
              left: '16px',
              pointerEvents: 'none',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '14.82px',
              letterSpacing: '0.08em',
            }
          }}
          fullWidth
        />
      </div>
      {suggestions.length > 0 && (
        <div className={styles.address_container}>
          {suggestions.map((address, index) => (
            <div key={index} className={styles.address_entry} onClick={() => onSelectAddress(address)}>
              <span className={`text_Cabinet ${styles.address}`}>
                <span className={styles.address_name}>{address.description}</span>
              </span>
            </div>
          ))}
        </div>
      )}
      <button className={styles.continue_button} onClick={onClick}>
        <p>Save Changes</p>
      </button>
    </div>
  );
}

export default EditUser;
