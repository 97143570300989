import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from "./EmailVerification.module.css";
import logo from '../../common/images/logo.png';
import home_bg from '../../common/images/home_bg.png';
import logofitness from '../../common/images/logofitness.png';
// import arrow_back from "../images/arrow_back.png";
import line from "../../common/images/line.png";
import logoGoogle from "../../common/images/logoGoogle.png"
import { styled, TextField as MuiTextField } from '@mui/material';
import { BackButton, ContinueButton, SendOptButton ,Button} from '../Buttons/Buttons';
import logoEdit from "../../common/images/logoEdit.png";


const StyledTextField = styled(MuiTextField)`
  width: 100%;
  height: 50vh;
  object-fit: cover;
  &::before {
      border-bottom: none; / Remove border just below the label /
    }
  label {
    font-family: "CabinetGrotesk-variable";
    position: relative;
    width:22.2rem;
    height: 1.6rem;
    top: .6rem;
    left: -.9rem;
    pointer-events: none;
    color: rgba(0, 0, 0, 1);
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.682rem;
    letter-spacing: 0.08em;
    opacity: 50%;
    &.Mui-focused {
      top: 1.6rem;
      // Other focused label styles...
    }
    &.MuiInputLabel-shrink {
      top: 1.6rem; // Keep the label at the top position when not focused
    }
  }
  label.Mui-focused {
    position: relative;
    width: 22.2rem;
    height:1.6rem;
    top: 2rem;
    left: -.9rem;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.482rem;
    color: rgba(0, 0, 0, 1);
    letter-spacing: 0.08em;
    
  }

 

  .MuiInput-underline  {
    border: none;
  }
 
`;


const EmailVerification = () => {
  
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [email, setEmail] = useState('');
  const [selectedOption, setSelectedOption] = useState('signup'); // State to track selected option
  const [login,Islogin] = useState(false);

  const [isEmail, setIsEmail] = useState(false);
  const [otpVerified,setOtpVerified] = useState(false);
  const [timer, setTimer] = useState(10);
  

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-DJ7XNB2XPG";
    script1.async = true;
    document.head.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-DJ7XNB2XPG');
    `;
    document.head.appendChild(script2);

    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []);

  useEffect(()=>{
    if (otpSent) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(interval);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [otpSent]); 
 
  const onClick = async() => {
   

    try {
      // Send email and OTP to backend for verification
      const response = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/hospital/verify-otp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email, otp })
      });
console.log(response);
      // Handle response
      if (response.ok) {
        alert("OTP verified successfully!");
        setTimer(10); 
        if(response.status === 200){
        
        // Handle successful verification, e.g., redirect to dashboard
        setOtpVerified(true);
        }else{
          if(response.status === 201){
            Islogin(true);
          }
        }
      } else {
        if (response.status === 400) {
          alert("Enter correct OTP");
        } else {
          alert("Failed to verify");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Set loading to false after OTP verification
    }
  };
  

 
  useEffect(() => {
    // Function to store Aadhaar number in local storage
    localStorage.setItem('email', email);
  }, [email]);

 if(login){
  Navigate('/hospital/dashboard');
 }

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };



  const [otp, setOtp] = useState(['', '', '', '']);


  const otpInputs = useRef([]);
  const navigate = useNavigate();

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      otpInputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace') {
      e.preventDefault(); // Prevent default Backspace behavior
      if (otp[index] !== '') {
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
      } else if (index > 0) {
        otpInputs.current[index - 1].focus(); // Move cursor to the leftmost empty input box
      }
    }
  };

   
  const onClickBack = () => {

    setIsEmail(false);
  };
  const onClickEmail = async () => {

    setLoading(true); // Set loading to true when sending OTP
    
    try {
      // Send email to backend to request OTP
      const response = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/hospital/send-otp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email })
      });

      // Handle response
      console.log(response)
      // if (response.status === 401) {
      //   alert("Email already registered!  Login initiated");
      // } else {
        if (response.ok) {
          setOtpSent(true);
        }
      
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Set loading to false after OTP is sent
    }

  };

  const loginWithGoogle = ()=>{
    window.open("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/auth/google/callback","_self")
  }
 
  if (otpVerified) {
    Navigate("/hospital/panVerification", { state: { email: email } });
  }
  const onClickContinue =async()=>{
    try {
      // Send email to backend to request OTP
      const response = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/hospital/send-otp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email })
      });

      // Handle response
      console.log(response)
      // if (response.status === 401) {
      //   alert("Email already registered!  Login initiated");
      // } else {
        if (response.ok) {
          setOtpSent(true);
        }
      
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Set loading to false after OTP is sent
    }
  }
  return (
    <div className={styles.container}>

    

      {otpSent? (
        <>
       
          <div className={styles.backButton_container}>
            <BackButton onClick={onClickBack} />
            <span className={styles.step}><p className='text_Cabinet'>Step 1 of 4</p></span>
          </div>

          <div className={styles.paragraph_container_a}>
            <p className={`text_Acron ${styles.paragraph}`}>Verify your email ID</p>
          </div>

          <div className={styles.otp_info}>
            <p className='text_Cabinet'>We have shared the OTP to your email ID
              <span className={styles.whitespace}> </span>
              <span style={{ fontWeight: 500, fontSize: '1.6rem', color: 'rgba(58, 171, 162, 1)' }}>{email}</span>


             
            </p>
          </div>


          <div className={styles.otp_container}>
            {otp.map((value, index) => (
              <input
                key={index}
                ref={(el) => otpInputs.current[index] = el}
                type="text"
                className={styles.otp_input}
                placeholder="X"
                maxLength="1"
                value={value}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
              />
            ))}

          </div>
          <div className={styles.resend_otp}>
            {timer > 0 ? (
              <p className='text_Cabinet'>Resend OTP in {timer}s</p>
            ) : (
              <p className='text_Cabinet' onClick={onClickContinue} style={{ cursor: 'pointer', color: 'blue' }}>Resend OTP</p>
            )}
          </div>
          <div>
         <div className={styles.continue_button}>
         <ContinueButton  onClick={onClick}>
            <p>Continue</p>
          </ContinueButton>
         </div>
          </div>
          
        </>



      ) : (


        <>

          <div className={styles.paragraph_container}>
            <p
              className={`text_Acron ${selectedOption === 'signup' ? styles.selected : ''}`}
              onClick={() => handleOptionClick('signup')}

            >
              Sign up
              {selectedOption === 'signup' && <div className={styles.line}></div>}
              {selectedOption === 'signup' && <img src={line} alt='line' />}
            </p>
            <span
              className={`text_Acron ${selectedOption === 'login' ? styles.selected : ''}`}
              onClick={() => handleOptionClick('login')}
            >
              Login
              {selectedOption === 'login' && <div className={styles.line}></div>}
              {selectedOption === 'login' && <img src={line} alt='line' />}
            </span>
          </div>

 
         { selectedOption === 'signup' &&  
          <>
          <div className={styles.emailID}>
            <input type = "email" 

              id="standard-basic"
              placeholder="Enter your Email ID"
              variant="standard"
              onChange={handleInputChange}
              inputProps={{ inputMode: 'numeric' }}
              InputProps={{ disableUnderline: true }}
              fullWidth
            />
          </div>




          <div className={styles.sendOtp_button}>
         <SendOptButton  onClick={onClickEmail}>
            <p>{loading?"Continue":"Sending Otp"}</p>
          </SendOptButton>
          </div>
          <div className={styles.or}>
            <p className='text_Cabinet'>or</p>
          </div>

          <div className={styles.continue_with_google} onClick={loginWithGoogle}>
            <img src={logoGoogle} alt='logoGoogle'></img>
            <span className='text_Cabinet'> Continue with Google</span>
          </div>
          </>
         }
         { selectedOption === 'login' &&  
          <>
          <div className={styles.emailID}>
            <input type = "email" 

              id="standard-basic"
              placeholder="Enter your Email ID"
              variant="standard"
              onChange={handleInputChange}
              inputProps={{ inputMode: 'numeric' }}
              InputProps={{ disableUnderline: true }}
              fullWidth
            />
          </div>




          <div className={styles.sendOtp_button}>
         <Button 
          onClick={onClickContinue}
          >
            <p>Continue</p>
          </Button>
          </div>
          <div className={styles.or}>
            <p className='text_Cabinet'>or</p>
          </div>

          <div className={styles.continue_with_google} onClick={loginWithGoogle}>
            <img src={logoGoogle} alt='logoGoogle'></img>
            <span className='text_Cabinet'> Continue with Google</span>
          </div>
          </>
         }


        </>
      )

      }

    </div>
  );
}

export default EmailVerification;