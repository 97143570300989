import React, { useState,useEffect } from 'react';
import styles from './Dashboard.module.css';
import logoHospital2 from '../../common/images/logoHospital2.png';
import headerContent from '../../common/images/headerContent.png';
import arrow_forward from '../../common/images/arrow_forward.png';
// import { Button, BackButton } from "../Buttons/Buttons";
import { useNavigate,useLocation } from 'react-router-dom';
// import { styled, TextField as MuiTextField } from '@mui/material';
// import IdentityVarified from '../Identity/IdentityVarified';
import crossBlack from "../../common/images/crossBlack.png";
import logoDashboard from '../../common/images/logoDashboard.png';
import logoTreatments from '../../common/images/logoTreatments.png';
import logoPatients from '../../common/images/logoPatients.png';
import logoLogout from '../../common/images/logoLogout.png';
import logoService from '../../common/images/logoService.png';
import Applied from '../../common/images/Applied.png';
import Approved from '../../common/images/Approved.png';
import Rejected from '../../common/images/Rejected.png';
import direction from '../../common/images/direction.png';

const Dashboard = () => {
  const location = useLocation();
  const { search } = location;
  const params = new URLSearchParams(search);
  console.log(params)
  let email = params.get('email');
  console.log(email)
  
  if (email !== null) {
    // Now you can use the email variable
    localStorage.setItem("email",email);
    console.log(email);
  } else {
    email = localStorage.getItem("email");
    console.log(email)
  }
const [userData,setUserData] = useState([]);
const [clickedPatient, setClickedPatient] = useState(null);
const currentDate = new Date();
const currentMonthIndex = currentDate.getMonth(); // This will return the index of the current month (0-indexed)
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const currentMonth = months[currentMonthIndex];
const currentYear = currentDate.getFullYear().toString().slice(-2); // Get the last two digits of the current year
const [hospitalData,setHospitalData] = useState('');
// Inside the component's return statement:


  const Navigate = useNavigate();
  useEffect(()=>{
    fetchUserData();
  },[])

  const fetchUserData = async()=>{
  
    try{
    const response = await fetch("https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/hospital/api/fetchUsersData",{
      method:"POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ email })
    });
    console.log(response)
    if(!response.ok) throw new Error('HTTP error! status: ${response.status}');
        const jsonData = await response.json();
        console.log(jsonData)
        // console.log(jsonData.hospitalData.hospitalName);
    // console.log(data);
    const {userData,hospitalData} = jsonData;
    setUserData(userData);
    
    setHospitalData(hospitalData)
    // const {name,serviconsolece_amount} = userData.services
console.log(hospitalData)
    }catch(err){
      console.log(err)
    }
  }
  const filteredPatients = userData.filter(patient => {
    const dateOfLogin = new Date(patient.DateofLogin);
    return dateOfLogin.getMonth() === currentMonthIndex && dateOfLogin.getFullYear() === currentDate.getFullYear();
  });
  
  const totalLoanAmount = userData.reduce((total, patient) => {
    if (patient.services && patient.services.length > 0 && patient.services[0].service_amount) {
      const serviceAmount = parseFloat(patient.services[0].service_amount);
      return total + (isNaN(serviceAmount) ? 0 : serviceAmount);
    }
    return total;
  }, 0);
  
  
  const onClickPatient = (patient) => {
    setClickedPatient(patient);
    console.log(clickedPatient)
  };
  const onClickBack = () => {
    Navigate('/service');
  }
  const onClickBackCross = () => {
    setClickedPatient(null);
  }
  const handleInputChange = () => {

  }



  return (
    <div className={styles.main_container}>
      <div className={styles.header_container}>
        <div className={`${styles.d} ${styles.logoContainer}`}>
          <div className={styles.logo}>
            <img src={`https://backend-dot-hallowed-index-423317-r7.el.r.appspot.com/${hospitalData.logo}`} alt='logoHospital2'></img>
          </div>
          <span className={`text_Acron ${styles.hospitalName}`}>{hospitalData.hospitalName}</span>
        </div>
        <div className={`${styles.d} ${styles.dashboard}`}>
          <img src={logoDashboard} alt='logoDashboard'></img>
          <p className={`text_Cabinet ${styles.dashboard_text}`}>Dashboard</p>
        </div>

        <div className={`${styles.d} ${styles.dashboard} ${styles.Treatments}`}>
          <img src={logoTreatments} alt='logoTreatments'></img>
          <p className='text_Cabinet'>Treatments</p>
        </div>
        <div className={`${styles.d} ${styles.dashboard} ${styles.Treatments}`}>
          <img src={logoPatients} alt='logoPatients'></img>
          <p className='text_Cabinet'>Patients</p>
        </div>

        <div className={`${styles.d} ${styles.dashboard} ${styles.Logout}`}>
          <img src={logoLogout} alt='logoLogout'></img>
          <p className='text_Cabinet'>Logout</p>
        </div>
      </div>

      <div className={styles.content_container}>
        <div className={styles.content}>
          <div className={`${styles.a} ${styles.namaste}`}>
            <p className='text_Acron'>Namaste 🙏</p>
          </div>
          <div className={`${styles.a} ${styles.patientsDetails}`}>

            <div className={styles.detailsBox}>
              <p className={`text_Cabinet ${styles.key}`}>New patients in {currentMonth} {currentYear}&#39;</p>
              <p className={`text_Acron ${styles.value}`}>{filteredPatients.length}</p>
            </div>
            <div className={styles.detailsBox}>

              <p className={`text_Cabinet ${styles.key}`}>Total patients</p>
              <p className={`text_Acron ${styles.value}`}>{userData.length}</p>
            </div>
            <div className={styles.detailsBox}>

              <p className={`text_Cabinet ${styles.key}`}>Total earning</p>
              <p className={`text_Acron ${styles.value}`}>₹{totalLoanAmount.toLocaleString()}</p>
            </div>
            <div className={styles.detailsBox}>

              <p className={`text_Cabinet ${styles.key}`}>Yet to be disbursed</p>
              <p className={`text_Acron ${styles.value}`}>₹20,00,000</p>
            </div>

          </div>
          <div className={styles.recentPatients}>
            <p className='text_Acron'>Recent patients</p>
          </div>
          <div className={styles.allPatients}>
            <div className={styles.keyContainer}>

              <div className={`text_Cabinet ${styles.keys}`}><p>ID</p></div>
              <div className={`text_Cabinet ${styles.keys}`}><p>Patient name</p></div>
              <div className={`text_Cabinet ${styles.keys}`}><p>Treatment</p></div>
              <div className={`text_Cabinet ${styles.keys}`}><p>Treatment amt.</p></div>
              <div className={`text_Cabinet ${styles.keys}`}><p>Loan amt.</p></div>
              <div className={`text_Cabinet ${styles.keys}`}>Loan status</div>

            </div>
            <div className={styles.valuesContainer}>

              {
                userData.map((patient,index) => (
                  <div key={index} className={styles.value_Container} onClick={() => onClickPatient(patient)}
                  //  onMouseEnter={() => setHovered(true)}
                  //   onMouseLeave={() => setHovered(false)}
                  >
                    <div className={`text_Cabinets ${styles.values}`}>
                    <p className='text_Cabinet'>{`MD${patient._id.slice(-4)}`}</p>

                    </div>
                    <div className={styles.values}>
                    <p className='text_Cabinet'>{patient.name ? patient.name : "empty"}</p>

                    </div>
                    <div className={styles.values}>
                      <img src={logoService} alt='logoService' className={styles.logoTreatment}></img>

                      <span className='text_Cabinet'>
                        
                        {patient.services[0].name}

                      </span>
                    </div>
                    <div className={styles.values}>

                      <p className='text_Cabinet'>
                        {patient.services[0].service_amount}
                      </p>
                    </div>
                    <div className={styles.values}>

                      <p className='text_Cabinet'> {patient.loanAmt ? patient.loanAmt : "₹ - - - -"}</p>
                    </div>
                    <div className={`${styles.values} `}>
                      {patient.loanStatus === 'Applied' && (
                        <img src={Applied} alt='Applied' className={styles.loanStatus} />
                      )}
                      {patient.loanStatus === 'Approved' && (
                        <img src={Approved} alt='Approved' className={styles.loanStatus} />
                      )}
                      {patient.loanStatus === 'Rejected' && (
                        <img src={Rejected} alt='Rejected' className={styles.loanStatus} />
                      )}

                      {(
                        <span className={styles.direction}>
                          <img src={direction} alt='direction'></img>
                        </span>
                      )}
                    </div>
                  </div>
                ))
              }

            </div>



          </div>

        </div>
      </div>

      {clickedPatient && (
  <div className={styles.overlay}>
    <div className={styles.identity}>
      <div className={styles.crossBack} onClick={onClickBackCross}>
      <img src={crossBlack} alt='crossBlack' />
      </div>
      <div className={styles.overlayContent}>
        {/* Render clicked patient's data */}
        <div className={`${styles.row} ${styles.one}`}>
          <div className={styles.cell}>
            <p className={`text_Acron ${styles.name}`}>{clickedPatient.name}</p>
            <p className={`text_Cabinet ${styles.second}`}>{clickedPatient._id ? `MD${clickedPatient._id.slice(-4)}` : ""}</p>

          </div>
        </div>
        <div className={`${styles.row} ${styles.two}`}>
          <div className={styles.cell}>
            <p className={`text_Cabinet ${styles.first}`}>Treatment</p>
            <div className={styles.treatmentContainer}>
              <img src={logoService} alt='logoService' />
              <p className={`text_Cabinet ${styles.second} ${styles.serviceName}`}>{clickedPatient.services[0].name}</p>
            </div>
          </div>
          <div className={styles.cell}>
            <p className={`text_Cabinet ${styles.first}`}>Treatment amt.</p>
            <p className={`text_Cabinet ${styles.second}`}>{clickedPatient.services[0].service_amount}</p>
          </div>
        </div>
        <div className={`${styles.row} ${styles.three}`}>
          <div className={styles.cell}>
            <p className={`text_Cabinet ${styles.first}`}>Loan amt.</p>
            <p className={`text_Cabinet ${styles.second}`}>{clickedPatient.loanAmt ? clickedPatient.loanAmt : "₹ - - - -"}</p>
          </div>
          <div className={styles.cell}>
            <p className={`text_Cabinet ${styles.first}`}>Loan status</p>
            <div className={styles.status}>
              {clickedPatient.loanStatus === 'Applied' && <img src={Applied} alt='Applied' className={`${styles.loanStatus} ${styles.second}`} />}
              {clickedPatient.loanStatus === 'Approved' && <img src={Approved} alt='Approved' className={`${styles.loanStatus} ${styles.second}`} />}
              {clickedPatient.loanStatus === 'Rejected' && <img src={Rejected} alt='Rejected' className={`${styles.loanStatus} ${styles.second}`} />}
            </div>
          </div>
        </div>
        <div className={`${styles.row} ${styles.four}`}>
          <div className={styles.cell}>
            <p className={`text_Cabinet ${styles.first}`}>Requested on</p>
            <p className={`text_Cabinet ${styles.second}`}>{clickedPatient.requestedOn}</p>
          </div>
          <div className={styles.cell}>
            <p className={`text_Cabinet ${styles.first}`}>Contact number</p>
            <p className={`text_Cabinet ${styles.second}`}>{clickedPatient.contactNumber}</p>
          </div>
        </div>
      </div>
    </div>
    <div className={styles.blur_effect}></div>
  </div>
)}

    </div >
  );
};

export default Dashboard;
