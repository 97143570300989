import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

// hospital routes
import LandingPage from "./components/common/LandingPage/LandingPage";
import Home from './components/HospitalComponents/Home/Home';
import HospitalLayout from './components/HospitalComponents/Layout/Layout';
import GstVerification from './components/HospitalComponents/GstVerification/GstVerification';
import EmailVerification from './components/HospitalComponents/EmailVerification/EmailVerification';
import IdentityVarified from './components/HospitalComponents/Identity/IdentityVarified';
import Service from './components/HospitalComponents/Service/Service';
import Vkyc from './components/HospitalComponents/Vkyc/Vkyc';
import AccountSetup from './components/HospitalComponents/AccountSetup/AccountSetup';
import Age from './components/HospitalComponents/Age/Age';
import Hospital from './components/HospitalComponents/Hospital/Hospital';
import Patients from './components/HospitalComponents/Patients/Patients';
import BankDetails from './components/HospitalComponents/BankDetails/BankDetails';
import Dashboard from './components/HospitalComponents/Dashboard/Dashboard';
import PanVerification from './components/HospitalComponents/PanVerification/PanVerification';
import Footer from './components/common/Footer/Footer';
import AboutUs from './components/common/AboutUs/AboutUs';

// user routes
import Login from './components/UserComponents/Login/Login';
import UserLayout from './components/UserComponents/Layout/Layout';
import MobileVerification from './components/UserComponents/Mobileverification/MobileVarification';
import AadhaarAndPan from './components/UserComponents/AdharAndPan/AadhaarAndPan';
import AadhaarVarification from './components/UserComponents/AadhaarVarification/AadhaarVarification';
import PanVarification from './components/UserComponents/PanVarification/PanVarification';
import UserIdentityVarified from './components/UserComponents/Identity/IdentityVarified';
import UserService from './components/UserComponents/hospitalService/Service';
import Amount from './components/UserComponents/Amount/Amount';
import FinalVerification from './components/UserComponents/FinalVerification/FinalVerification';
import CibilScore from './components/UserComponents/CibilScore/CibilScore';
import PersonalDetail from './components/UserComponents/PersonalDetail/PersonalDetail';
import DocumentsVerification from './components/UserComponents/DocumentsVerification/DocumentsVerification';
import LoanApproved from './components/UserComponents/LoanApproved/LoanApproved';
import UserDashboard from './components/UserComponents/Dashboard/Dashboard';
import EditUser from "./components/UserComponents/EditUserDashboard/EditUser";
import UserProfile from './components/UserComponents/UserProfile/UserProfile';

import { useRecording } from './hooks/useRecording';

function App() {
  const { startRecording, videosrc, setVideoSrc, mediaRecorder, permission, isRecording, setPermission, setIsRecording } = useRecording();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path='/aboutUs' element={<AboutUs/>}/>
        
        {/* User Routes */}
        <Route path="/user/*" element={<UserLayout />}>
          <Route index element={<Login />} />
          <Route path="mobileVerification" element={<MobileVerification />} />
          <Route path="aadhaarandPan" element={<AadhaarAndPan />} />
          <Route path="aadhaarVarification" element={<AadhaarVarification />} />
          <Route path="panVarification" element={<PanVarification />} />
          <Route path="identityVarified" element={<UserIdentityVarified />} />
          <Route path="service" element={<UserService />} />
          <Route path="amount" element={<Amount />} />
          <Route path="finalVerification" element={<FinalVerification />} />
          <Route path="cibilScore" element={<CibilScore />} />
          <Route path="personalDetail" element={<PersonalDetail />} />
          <Route path="documentsVerification" element={<DocumentsVerification />} />
          <Route path="loanApproved" element={<LoanApproved />} />
          <Route path="dashboard" element={<UserDashboard />} />
          <Route path="userProfile" element={<UserProfile />} />
          <Route path="editprofile" element={<EditUser />} />
        </Route>

        {/* Hospital Routes */}
        <Route path="/hospital/*" element={<HospitalLayout />}>
          <Route index element={<Home />} />
          <Route path="emailverification" element={<EmailVerification />} />
          <Route path="identityVarification" element={<IdentityVarified />} />
          <Route path="service" element={<Service setVideoSrc={setVideoSrc} recording={isRecording} />} />
          <Route path="gstVerification" element={<GstVerification />} />
          <Route path="vkyc" element={<Vkyc videoSrc={videosrc} setVideoSrc={setVideoSrc} startRecording={startRecording} permission={permission} setPermission={setPermission} />} />
          <Route path="accountSetup" element={<AccountSetup videoSrc={videosrc} recording={isRecording} />} />
          <Route path="age" element={<Age videoSrc={videosrc} recording={isRecording} />} />
          <Route path="hospital" element={<Hospital videoSrc={videosrc} recording={isRecording} />} />
          <Route path="patients" element={<Patients videoSrc={videosrc} recording={isRecording} />} />
          <Route path="bankDetails" element={<BankDetails videoSrc={videosrc} mediaRecorder={mediaRecorder} recording={isRecording} setRecording={setIsRecording} />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="panVerification" element={<PanVerification />} />
          <Route path="aboutUs" element={<AboutUs />} />
          <Route path="footer" element={<Footer />} />
          <Route path="forPatient" element={<Login />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
